import { getPolicyCompletedByIdEndpoint as endpoint } from "../apiEndpoints";
import { GetPolicyCompletedByIdProps } from "../getPoliciesTypes";

const getPolicyCompletedByIdAPI = async ({
  header,
  policyCompletedById,
  startDate,
  endDate,
}: GetPolicyCompletedByIdProps) => {
  return fetch(endpoint(policyCompletedById, startDate!, endDate!), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getPolicyCompletedByIdAPI;
