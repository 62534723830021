import getBookingDashboardAPI from "./getBookingDashboardAPI";
import { getBookingDashboardProps } from "../getDashbaordTypes";

const getBookingDashboardService = async ({
  header,
  bookingUserId,
  startDate,
  endDate,
}: getBookingDashboardProps) => {
  return getBookingDashboardAPI({
    header: header,
    bookingUserId: bookingUserId,
    startDate,
    endDate,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `getBookingDashboardAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getBookingDashboardService;
