
import { GetPolicyByNumberProps } from "../getPoliciesTypes";
import getPolicyDataByPolicyNumberAPI from "./getPolicyDataByPolicyNumberAPI";

const getPolicyDataByPolicyNumberService = async ({
    header,
    policyNumber,
}: GetPolicyByNumberProps) => {
    return getPolicyDataByPolicyNumberAPI({
        header: header,
        policyNumber: policyNumber,
    })
        .then((policy) => {
            return policy;
        })
        .catch((response) => {
            console.error(
                `getPolicyByNumberAPI failed with http status = ${response.status}`
            );
            return Promise.reject(response.json());
        });
};

export default getPolicyDataByPolicyNumberService;