/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SafeKaroUser, header } from "../../context/constant";
import { IPartnerData } from "./IDashboard";
import BrokersIcon from "../../assets/broker.png";
import FinalPremiumIcon from "../../assets/finalPremium.png";
import MotorIcon from "../../assets/motor1.png";
import NetPremiumIcon from "../../assets/netPremium.png";
import AcceptedBookingIcon from "../../assets/acceptedBooking.png";
import RequestedBookingIcon from "../../assets/requestedBooking.png";
import TotalLeadIcon from "../../assets/totalLead.png";
import deleteIcon from "../../assets/delete.png";
import PayInCommissionIcon from "../../assets/payIn.png";
import PayOutCommissionIcon from "../../assets/payOut.png";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Form } from "react-final-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import getPartnerDashboardService from "../../api/Dashboard/GetPartnerDashboard/getPartnerDashboardService";
import PartnerPolicyChart from "./Chart/PartnerPolicyChart";
import PayOutCommisionChart from "./Chart/PayOutCommisionChart";
import { Link } from "react-router-dom";

const partnerDashboard: React.FC = () => {
  const [data, setData] = useState<IPartnerData[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [firstCart, setFirstCart] = useState(true);
  const [secondCart, setSecondCart] = useState(false);
  const [thirdCart, setThirdCart] = useState(false);
  const [fourCart, setFourCart] = useState(false);
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;

  const GetDashboardCount = useCallback(
    (startDate, endDate) => {
      getPartnerDashboardService({
        header,
        startDate,
        endDate,
        partnerId: UserData.partnerId,
      })
        .then((dashboardData) => {
          setIsVisible(true);
          setData(dashboardData.data);
        })
        .catch((error) => {
          setIsVisible(false);
          console.error("Failed to fetch product details", error);
        });
    },
    [UserData.partnerId]
  );

  useEffect(() => {
    const currentDate = new Date(); // Example current date

    // Calculate first day of current month
    const firstDayOfMonth = startOfMonth(currentDate);

    // Calculate last day of current month
    const lastDayOfMonth = endOfMonth(currentDate);
    // Format the dates if needed
    const formattedFirstDay = format(firstDayOfMonth, "yyyy-MM-dd");
    const formattedLastDay = format(lastDayOfMonth, "yyyy-MM-dd");
    const fetchData = () => {
      GetDashboardCount(formattedFirstDay, formattedLastDay);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [GetDashboardCount]);

  const renderCountBox = (
    title: string,
    count: number,
    icon: string,
    path = "/partnerdashboard/withdrawal"
  ) => {
    const formattedCount = Math.round(count).toLocaleString(); // Format numbers with commas for readability

    const content = (
      <div className="bg-white m-2 p-3 rounded-2xl shadow-lg flex items-center justify-between transform transition-transform duration-200 hover:scale-105">
        <div>
          <Typography variant="body2" className="text-sm text-gray-600 mb-2">
            {title}
          </Typography>
          <Typography
            variant="h5"
            className="text-base font-bold text-gray-800"
          >
            {formattedCount}
          </Typography>
        </div>
        <img src={icon} alt={title} className="h-8 w-8" />
      </div>
    );

    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {path ? <Link to={path}>{content}</Link> : content}
      </Grid>
    );
  };

  const onSubmit = async (value: any) => {
    // Convert to local time zone
    // Create a Date object from the UTC date string
    const utcStartDate = new Date(value.startDate!);
    // Format the date
    const formattedStartDate = format(utcStartDate, "yyyy-MM-dd'T'HH:mm:ss");
    value.startDate = formattedStartDate;
    // Create a Date object from the UTC date string
    const utcEndDate = new Date(value.endDate!);
    const formattedEndDate = format(utcEndDate, "yyyy-MM-dd'T'HH:mm:ss");
    value.endDate = formattedEndDate;

    GetDashboardCount(value.startDate, value.endDate);
  };

  const handleFirstCart = async () => {
    setFirstCart(true);
    setSecondCart(false);
    setThirdCart(false);
    setFourCart(false);
  };
  const handleSecondCart = async () => {
    setFirstCart(false);
    setSecondCart(true);
    setThirdCart(false);
    setFourCart(false);
  };
  const handleThirdCart = async () => {
    setFirstCart(false);
    setSecondCart(false);
    setThirdCart(true);
    setFourCart(false);
  };

  return (
    <div className="bg-blue-200 p-7">
      <Card>
        <CardContent>
          <Grid container>
            <Grid item md={5}>
              <Typography variant="h6">
                Welcome {UserData.name.toUpperCase()}!
              </Typography>
            </Grid>
            <Grid item md={7} mb={3}>
              <Form
                onSubmit={onSubmit}
                // initialValues={initialValues}
                //validate={validate}
                render={({ handleSubmit, submitting, errors, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={input.value || null} // Initialize the value if it's undefined
                                onChange={(date) => {
                                  input.onChange(date);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    {...params}
                                    error={meta.touched && !!meta.error}
                                    helperText={meta.touched && meta.error}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Field>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Field name="endDate">
                          {({ input, meta }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={input.value || null} // Initialize the value if it's undefined
                                onChange={(date) => {
                                  input.onChange(date);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    {...params}
                                    error={meta.touched && !!meta.error}
                                    helperText={meta.touched && meta.error}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Field>
                      </Grid>
                      {/* Buttons for filtering partnerHistoryCard */}
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="w-4 h-4 bg-addButton text-white p-5 text-xs rounded-sm"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                            />
                          </svg>
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
            <Grid
              item
              md={3}
              className={`p-2 ${
                firstCart ? "bg-blue-200 shadow-md" : "bg-white"
              }`}
            >
              <Button type="button" onClick={handleFirstCart}>
                <Tooltip title={"View Payment Data"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-10"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </Tooltip>
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              className={`p-2 ${
                secondCart ? "bg-blue-200 shadow-md" : "bg-white"
              }`}
            >
              <Button type="button" onClick={handleSecondCart}>
                <Tooltip title={"View Partner Data"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                    />
                  </svg>
                </Tooltip>
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              className={`p-2 ${
                thirdCart ? "bg-blue-200 shadow-md" : "bg-white"
              }`}
            >
              <Button type="button" onClick={handleThirdCart}>
                <Tooltip title={"View Chart"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                    />
                  </svg>
                </Tooltip>
              </Button>
            </Grid>

            <Grid item md={12}>
              {UserData.role === "Partner" ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {isVisible ? (
                        <>
                          {firstCart && (
                            <>
                              {data.map((item, index) => (
                                <div key={index}>
                                  <div className="bg-blue-200 p-7">
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Balance",
                                        item.commissions?.["Balance"] || 0,
                                        BrokersIcon
                                      )}
                                      {renderCountBox(
                                        "Net Premium",
                                        item.premiums?.["Net Premium"] || 0,
                                        NetPremiumIcon
                                      )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Monthly PayOut",
                                        item.commissions?.[
                                          "Monthly Commission"
                                        ] || 0,
                                        PayInCommissionIcon
                                      )}
                                      {renderCountBox(
                                        "Monthly Paid PayOut",
                                        item.commissions?.[
                                          "Monthly Paid Amount"
                                        ] || 0,
                                        PayOutCommissionIcon
                                      )}
                                      {renderCountBox(
                                        "Monthly UnPaid PayOut",
                                        item.commissions?.[
                                          "Monthly UnPaid Amount"
                                        ] || 0,
                                        FinalPremiumIcon
                                      )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Total PayOut",
                                        item.commissions?.[
                                          "Total Commission"
                                        ] || 0,
                                        PayOutCommissionIcon
                                      )}
                                      {renderCountBox(
                                        "Total Paid PayOut",
                                        item.commissions?.[
                                          "Total Paid Amount"
                                        ] || 0,
                                        PayOutCommissionIcon
                                      )}
                                      {renderCountBox(
                                        "Total UnPaid PayOut",
                                        item.commissions?.[
                                          "Total UnPaid Amount"
                                        ] || 0,
                                        FinalPremiumIcon
                                      )}
                                    </Grid>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                          {secondCart && (
                            <>
                              {data.map((item, index) => (
                                <div key={index}>
                                  <div className="bg-blue-200 p-7">
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Total Bookings",
                                        item.policyCounts?.motor || 0,
                                        MotorIcon,
                                        "/booking"
                                      )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Requested Booking",
                                        item.bookingRequests?.[
                                          "Requested Booking"
                                        ] || 0,
                                        RequestedBookingIcon,
                                        "/booking"
                                      )}
                                      {renderCountBox(
                                        "Accepted Booking",
                                        item.bookingRequests?.[
                                          "Accepted Booking"
                                        ] || 0,
                                        AcceptedBookingIcon,
                                        "/booking"
                                      )}
                                      {renderCountBox(
                                        "Rejected Booking",
                                        item.bookingRequests?.[
                                          "Rejected Booking"
                                        ] || 0,
                                        deleteIcon,
                                        "/booking/reject"
                                      )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Total Lead",
                                        item.leadCounts?.["Total Lead"] || 0,
                                        TotalLeadIcon,
                                        "/lead"
                                      )}
                                    </Grid>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                          {thirdCart && (
                            <div className="bg-blue-200 p-7">
                              <Grid container spacing={2}>
                                <Grid item md={6}>
                                  <PayOutCommisionChart
                                    partnerId={UserData.partnerId!}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <PartnerPolicyChart
                                    partnerId={UserData.partnerId!}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </>
                      ) : (
                        <Typography variant="h6">Loading...</Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography variant="h6">Access Denied</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default partnerDashboard;
