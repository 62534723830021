import getAccountManageByAccountIdAPI from "./getAccountManageByAccountIdAPI";
import { GetAccountManageByIdProps } from "../getCreditDebitTypes";

const getAccountManageByAccountIdService = async ({
  header,
  accountId,
}: GetAccountManageByIdProps) => {
  return getAccountManageByAccountIdAPI({
    header: header,
    accountId: accountId,
  })
    .then((accounts) => {
      return accounts;
    })
    .catch((response) => {
      console.error(
        `getAccountManageByAccountIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getAccountManageByAccountIdService;
