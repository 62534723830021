import getAccountByIdAPI from "./getAccountByIdAPI";
import { GetAccountByIdProps } from "../getAccountTypes";
import convertIAccountToIAccountVM from "../convertIAccountToIAccountVM";

const getAccountByIdService = async ({
  header,
  accountId,
}: GetAccountByIdProps) => {
  return getAccountByIdAPI({
    header: header,
    accountId: accountId,
  })
    .then((bookingRequestes) => {
      const accounts = convertIAccountToIAccountVM(bookingRequestes.data);
      return accounts;
    })
    .catch((response) => {
      console.error(
        `getAccountByIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getAccountByIdService;
