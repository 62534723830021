
import { GetBookingRequestPartnerProps } from "../getBookingRequestTypes";
import RejectedBookingPartnerAPI from './RejectedBookingPartnerAPI'
const RejectedBookingPartnerService = async ({ header,partnerId }: GetBookingRequestPartnerProps) => {
    return RejectedBookingPartnerAPI({
        header: header,partnerId
    })
        .then((bookingRequests) => {
            return bookingRequests;
        })
        .catch((response) => {
            console.error(`getBookingRequestAPI failed with http status = ${response.status}`);
            return Promise.reject(response.json());
        });
};

export default RejectedBookingPartnerService;