import { rejectedBookingPartnerEndpoint as endpoint } from "../apiEndPoints";
import { GetBookingRequestPartnerProps } from "../getBookingRequestTypes";

const RejectedBookingPartnerAPI = async ({ header,partnerId }: GetBookingRequestPartnerProps) => {
  return fetch(endpoint(partnerId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default RejectedBookingPartnerAPI;