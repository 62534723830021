import { getPolicyRevenueEndpoint as endpoint } from "../apiEndPoints";
import { getPolicyProps } from "../getDashbaordTypes";

const GetRevenueDataAPI = async ({ header, filter }: getPolicyProps) => {
    return fetch(endpoint(filter), {
        method: "GET",
        headers: header,
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    });
};

export default GetRevenueDataAPI;
