import getMotorPolicyAPI from "./getMotorPolicyAPI";
import { GetMotorPoliciesProps } from "../getPoliciesTypes";

const getMotorPolicyService = async ({
  header,
  startDate,
  endDate,
}: GetMotorPoliciesProps) => {
  return getMotorPolicyAPI({
    header: header,
    startDate: startDate,
    endDate: endDate,
  })
    .then((motorPolicies) => {
      return motorPolicies;
    })
    .catch((response) => {
      console.error(
        `getMotorPolicyAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getMotorPolicyService;
