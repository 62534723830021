import GetAccountManageByPartnerByDateRangeAPI from "./GetAccountManageByPartnerByDateRangeAPI";
import { GetCreditDebitByPartnerDateRangeProps } from "../getCreditDebitTypes";
//import convertICreditDebitToICreditDebitVM from "../convertICreditDebitToICreditDebitVM";

const GetAccountManageByPartnerByDateRangeService = async ({
  header,
  partnerId,
  startDate,
  endDate,
}: GetCreditDebitByPartnerDateRangeProps) => {
  return GetAccountManageByPartnerByDateRangeAPI({
    header: header,
    partnerId: partnerId,
    startDate: startDate,
    endDate: endDate,
  })
    .then((creditDebits) => {
      // const creditDebit = convertICreditDebitToICreditDebitVM(
      //   creditDebits.data
      // );
      return creditDebits;
    })
    .catch((response) => {
      console.error(
        `GetAccountManageByPartnerByDateRangeAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetAccountManageByPartnerByDateRangeService;
