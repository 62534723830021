import { addAccountEndpoint as endpoint } from "../apiEndpoints";
import { AddEditAccountProps } from "../getAccountTypes";

const addAccountAPI = async ({ header, account }: AddEditAccountProps) => {
  return fetch(endpoint(), {
    method: "POST",
    headers: header,
    body: JSON.stringify({ ...account }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default addAccountAPI;
