// const API_ENDPOINT_HOST: string =
//   process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const addBookingRequestEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/booking-request`);
export const getBookingRequestByIdEndpoint = (bookingRequestId: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/booking-request/booking-id/${bookingRequestId}`
  );
export const editBookingRequestEndpoint = (bookingRequestId: string) =>
  API_ENDPOINT_HOST.concat(`/api/booking-request/${bookingRequestId}`);
export const getBookingRequestEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/booking-request`);

export const getBookingRequestAdminEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/booking-request/accepted-bookings`);
export const validateBookingRequestEndpoint = (policyNumber: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/booking-request/validatePolicyNumber?policyNumber=${policyNumber}`
  );
export const getBookingRequestByOperationIdEndpoint = (userId: string) =>
  API_ENDPOINT_HOST.concat(`/api/booking-request/created-by/${userId}`);

export const getBookingRequestbyPartnerIdEndpoint = (partnerId: string) =>
  API_ENDPOINT_HOST.concat(`/api/booking-request/partner/${partnerId}`);

export const acceptBookingRequest = (bookingId: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/booking-request/accepted-booking/${bookingId}`
  );

export const getBookingRequestbyBookingIdEndpoint = (userId: string) =>
  API_ENDPOINT_HOST.concat(`/api/booking-request/accepted-by/${userId}`);

export const getRejectedBookingReqEndpoint = () => {
  return (API_ENDPOINT_HOST.concat(`/api/booking-request/rejected-bookings`))

}
export const rejectedBookingPartnerEndpoint = (partnerId: string) => {
  return (API_ENDPOINT_HOST.concat(`/api/booking-request/rejected-partner-id?partnerId=${partnerId}`))

}
