import GetTotalPartnerPaymentAPI from "./GetTotalPartnerPaymentAPI";
import { getTotalPartnerPaymentProps } from "../getDashbaordTypes";

const GetTotalPartnerPaymentService = async ({
  header,
}: getTotalPartnerPaymentProps) => {
  return GetTotalPartnerPaymentAPI({
    header: header,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `GetTotalPartnerPaymentAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetTotalPartnerPaymentService;
