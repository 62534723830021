import getBookingRequestByOperationIdAPI from "./getBookingRequestByOperationIdAPI";
import { GetBookingRequestByOperationIdProps } from "../getBookingRequestTypes";

const getBookingRequestByOperationIdService = async ({
  header,
  userId,
}: GetBookingRequestByOperationIdProps) => {
  return getBookingRequestByOperationIdAPI({
    header: header,
    userId: userId,
  })
    .then((bookingRequestes) => {
      return bookingRequestes;
    })
    .catch((response) => {
      console.error(
        `getBookingRequestByOperationIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getBookingRequestByOperationIdService;
