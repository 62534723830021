import { payOutExcelEndpoint as endpoint } from "../apiEndpoints";
import { GetExcelProps } from "../getUploadTypes";

const PayOutExcelAPI = async ({ header, excel }: GetExcelProps) => {
  return fetch(endpoint(), {
    method: "POST",
    //headers: header,
    body: excel,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default PayOutExcelAPI;
