import { getBookingRequestByOperationIdEndpoint as endpoint } from "../apiEndPoints";
import { GetBookingRequestByOperationIdProps } from "../getBookingRequestTypes";

const getBookingRequestByOperationIdAPI = async ({
  header,
  userId,
}: GetBookingRequestByOperationIdProps) => {
  return fetch(endpoint(userId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getBookingRequestByOperationIdAPI;
