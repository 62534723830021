import { motorPolicyExcelEndpoint as endpoint } from "../apiEndpoints";
import { GetMotorPolicyExcelProps } from "../getPoliciesTypes";

const MotorPolicyExcelAPI = async ({
  header,
  excel,
}: GetMotorPolicyExcelProps) => {
  return fetch(endpoint(), {
    method: "POST",
    body: excel,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default MotorPolicyExcelAPI;
