import motorPolicyPDFAPI from "./motorPolicyPDFAPI";
import { GetMotorPoliciesPDFProps } from "../getPoliciesTypes";

const motorPolicyPDFService = async ({
  header,
  file,
}: GetMotorPoliciesPDFProps) => {
  return motorPolicyPDFAPI({
    header: header,
    file: file,
  })
    .then((motorPolicies) => {
      return motorPolicies;
    })
    .catch((response) => {
      console.error(
        `getMotorPolicyAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default motorPolicyPDFService;
