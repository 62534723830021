import DebitHistoryAPI from "./DebitHistoryAPI";
import { DebitPartnerProps } from "../getPartnersTypes";

const DebitHistoryService = async ({
  header,
  startDate,
  endDate,
  partnerId,
}: DebitPartnerProps) => {
  return DebitHistoryAPI({
    header: header,
    partnerId: partnerId,
    startDate: startDate,
    endDate: endDate,
  })
    .then((cardDetails) => {
      return cardDetails;
    })
    .catch((response) => {
      console.error(
        `DebitHistoryAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default DebitHistoryService;
