import getAccountAPI from "./getAccountAPI";
import { GetAccountProps } from "../getAccountTypes";

const getAccountService = async ({ header }: GetAccountProps) => {
  return getAccountAPI({
    header: header,
  })
    .then((accountRequest) => {
      return accountRequest;
    })
    .catch((response) => {
      console.error(
        `getAccountAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getAccountService;
