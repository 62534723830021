import { getAccountDashboardEndpoint as endpoint } from "../apiEndPoints";
import { getAccountDashboardProps } from "../getDashbaordTypes";

const getAccountDashboardAPI = async ({
  header,
  startDate,
  endDate,
}: getAccountDashboardProps) => {
  return fetch(endpoint(startDate!, endDate!), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getAccountDashboardAPI;
