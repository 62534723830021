import getLeadByPartnerIdAPI from "./getLeadByPartnerIdAPI";
import { GetLeadByPartnerIdProps } from "../getLeadsTypes";

const getLeadByPartnerIdService = async ({
  header,
  partnerId,
}: GetLeadByPartnerIdProps) => {
  return getLeadByPartnerIdAPI({
    header: header,
    partnerId: partnerId,
  })
    .then((leads) => {
      return leads;
    })
    .catch((response) => {
      console.error(
        `getLeadByPartnerIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getLeadByPartnerIdService;
