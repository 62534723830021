import TransactionHistoryAPI from "./TransactionHistoryAPI";
import { DebitTransactionProps } from "../getPartnersTypes";

const TransactionHistoryService = async ({
  header,
  transactionCode,
  partnerId,
}: DebitTransactionProps) => {
  return TransactionHistoryAPI({
    header: header,
    partnerId: partnerId,
    transactionCode: transactionCode,
  })
    .then((cardDetails) => {
      return cardDetails;
    })
    .catch((response) => {
      console.error(
        `TransactionHistoryAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default TransactionHistoryService;
