import { getTotalPartnerPaymentEndpoint as endpoint } from "../apiEndPoints";
import { getTotalPartnerPaymentProps } from "../getDashbaordTypes";

const GetTotalPartnerPaymentAPI = async ({ header }: getTotalPartnerPaymentProps) => {
  return fetch(endpoint(), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default GetTotalPartnerPaymentAPI;
