import { validateModelEndpoint as endpoint } from "../apiEndpoints";
import { ValidateModelDetailsProps } from "../getModelsTypes";

const validateModelAPI = async ({
  header,
  modelName,
}: ValidateModelDetailsProps) => {
  return fetch(endpoint(modelName!), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default validateModelAPI;
