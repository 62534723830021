/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react";
import { header, SafeKaroUser } from "../../context/constant";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import deleteIcon from "../../assets/delete.png";
import MotorIcon from "../../assets/motor1.png";
import NetPremiumIcon from "../../assets/netPremium.png";
import FinalPremiumIcon from "../../assets/finalPremium.png";
import TotalBookingIcon from "../../assets/totalBooking.png";
import AcceptedBookingIcon from "../../assets/acceptedBooking.png";
import BookedBookingIcon from "../../assets/bookedBooking.png";
import RequestedBookingIcon from "../../assets/requestedBooking.png";
import { IBookingData } from "./IDashboard";
import getBookingDashboardService from "../../api/Dashboard/GetBookingDashboard/getBookingDashboardService";
import { endOfMonth, startOfMonth, format } from "date-fns";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const bookingDashboard: React.FC = () => {
  const [data, setData] = useState<IBookingData[]>([]);
  const [isVisible, setIsVisible] = useState(false);

  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;

  const GetDashboardCount = useCallback(
    (startDate: string, endDate: string) => {
      getBookingDashboardService({
        header,
        bookingUserId: UserData.id,
        startDate,
        endDate,
      })
        .then((dashboardData) => {
          setIsVisible(true);
          setData(dashboardData.data);
        })
        .catch((error) => {
          setIsVisible(true);
          console.error("Failed to fetch product details", error);
        });
    },
    [UserData.id]
  );

  useEffect(() => {
    // Calculate first day of current month
    const currentDate = new Date();
    const firstDayOfMonth = startOfMonth(currentDate);
    // Calculate last day of current month
    const lastDayOfMonth = endOfMonth(currentDate);
    // Format the dates if needed
    const formattedFirstDay = format(firstDayOfMonth, "yyyy-MM-dd");
    const formattedLastDay = format(lastDayOfMonth, "yyyy-MM-dd");
    const fetchData = () => {
      GetDashboardCount(formattedFirstDay, formattedLastDay);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [GetDashboardCount]);

  const renderCountBox = (
    title: string,
    count: number,
    icon: string,
    path?: string
  ) => {
    const formattedCount = Math.round(count).toLocaleString(); // Format numbers with commas for readability

    const content = (
      <div className="bg-white m-2 p-3 rounded-2xl shadow-lg flex items-center justify-between transform transition-transform duration-200 hover:scale-105">
        <div>
          <Typography variant="body2" className="text-sm text-gray-600 mb-2">
            {title}
          </Typography>
          <Typography
            variant="h5"
            className="text-base font-bold text-gray-800"
          >
            {formattedCount}
          </Typography>
        </div>
        <img src={icon} alt={title} className="h-8 w-8" />
      </div>
    );

    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {path ? <Link to={path}>{content}</Link> : content}
      </Grid>
    );
  };

  return (
    <div className="bg-blue-200 p-7">
      <Grid container>
        <Grid item lg={12}>
          <Typography variant="h6">Welcome!</Typography>
        </Grid>
        <Grid item lg={12}>
          {isVisible ? (
            <>
              {data &&
                data.map((item: IBookingData, index: number) => (
                  <div key={index}>
                    <Grid container spacing={2}>
                      {renderCountBox(
                        "Motor",
                        item.policyCounts?.motor || 0,
                        MotorIcon,
                        "/policy/motorpolicies"
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {renderCountBox(
                        "Net Premium",
                        item.premiums?.["Net Premium"] || 0,
                        NetPremiumIcon,
                        "/policy/motorpolicies"
                      )}
                      {renderCountBox(
                        "Final Premium",
                        item.premiums?.["Final Premium"] || 0,
                        FinalPremiumIcon,
                        "/policy/motorpolicies"
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {renderCountBox(
                        "Total Booking",
                        item.bookingRequests?.["Total Booking"] || 0,
                        TotalBookingIcon,
                        "/booking"
                      )}
                      {renderCountBox(
                        "Requested Booking",
                        item.bookingRequests?.["Requested Booking"] || 0,
                        RequestedBookingIcon,
                        "/booking"
                      )}
                      {renderCountBox(
                        "Accepted Booking",
                        item.bookingRequests?.["Accepted Booking"] || 0,
                        AcceptedBookingIcon,
                        "/booking"
                      )}
                      {renderCountBox(
                        "Booked Booking",
                        item.bookingRequests?.["Booked Booking"] || 0,
                        BookedBookingIcon,
                        "/policy/motorpolicies"
                      )}
                      {renderCountBox(
                        "Rejected Booking",
                        item.bookingRequests?.["Rejected Booking"] || 0,
                        deleteIcon,
                        "/booking/reject"
                      )}
                    </Grid>
                  </div>
                ))}
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}></Grid>
                <Grid item xs={6} md={6}></Grid>
              </Grid>
            </>
          ) : (
            <Typography variant="h6">Loading...</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default bookingDashboard;
