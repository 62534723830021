import addPercenatgeAPI from "./addPercenatgeAPI";
import { AddEditPercentageProps } from "../getPercentageTypes";

const addPercentageService = async ({
  header,
  policy,
}: AddEditPercentageProps) => {
  return addPercenatgeAPI({
    header: header,
    policy: policy,
  })
    .then((newPercentage) => {
      return newPercentage;
    })
    .catch((response) => {
      console.error(
        `addPercenatgeAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default addPercentageService;
