import { getAccountManageByPartnerDateRangeEndpoint as endpoint } from "../apiEndpoints";
import { GetCreditDebitByPartnerDateRangeProps } from "../getCreditDebitTypes";

const GetAccountManageByPartnerByDateRangeAPI = async ({
  header,
  partnerId,
  startDate,
  endDate,
}: GetCreditDebitByPartnerDateRangeProps) => {
  return fetch(endpoint(partnerId, startDate, endDate), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default GetAccountManageByPartnerByDateRangeAPI;
