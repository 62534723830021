import { getOperationDashboardEndpoint as endpoint } from "../apiEndPoints";
import { getOperationDashboardProps } from "../getDashbaordTypes";

const getOperationDashboardAPI = async ({
  header,
  operationUserId,
}: getOperationDashboardProps) => {
  return fetch(endpoint(operationUserId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getOperationDashboardAPI;
