// Lead API Endpoints
//const API_ENDPOINT_HOST: string =
// process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const payInExcelEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/pay-in/excel`);
export const payOutExcelEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/pay-out/excel`);
