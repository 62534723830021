// NotificationCard.tsx

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { NotificationImportant as NotificationIcon } from '@mui/icons-material';

// Define the props for the NotificationCard component
interface NotificationCardProps {
  message: string;
  title?: string;
  type?: string;
  role?: string;
  notificationFor?: string;
  notificationBy?: string;
  isView?: Boolean;
  isActive?: Boolean;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: any;
  updatedOn?: any
  onclick?: (nId: string) => void
}

const NotificationCard: React.FC<NotificationCardProps> = ({ title, message, onclick }) => {
  return (
    <Card sx={{ margin: 'auto', boxShadow: 3 }} >
      <CardContent sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} onClick={() => onclick && onclick("aja1")}>
        <NotificationIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
        <div>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {message}
          </Typography>
        </div>

      </CardContent>


    </Card>
  );
};

export default NotificationCard;
