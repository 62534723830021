import getPolicyByPartnerIdAPI from "./getPolicyByPartnerIdAPI";
import { GetPolicyByPartnerIdProps } from "../getPoliciesTypes";

const getPolicyByPartnerIdService = async ({
  header,
  partnerId,
  startDate,
  endDate,
}: GetPolicyByPartnerIdProps) => {
  return getPolicyByPartnerIdAPI({
    header: header,
    partnerId: partnerId,
    startDate,
    endDate,
  })
    .then((partnerMotorPolicies) => {
      return partnerMotorPolicies;
    })
    .catch((response) => {
      console.error(
        `getPolicyByPartnerIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getPolicyByPartnerIdService;
