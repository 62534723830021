import updateFilterPaymentsAPI from "./updateFilterPaymentsAPI";
import { updateFilterPaymentsTypeProps } from "../getCalculatationTypes";

const updateFilterPaymentsService = async ({
  header,
  policyData,
}: updateFilterPaymentsTypeProps) => {
  return updateFilterPaymentsAPI({
    header,
    policyData,
  })
    .then((policyRecord) => {
      return policyRecord;
    })
    .catch((response) => {
      console.error(
        `updateFilterPaymentsAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default updateFilterPaymentsService;
