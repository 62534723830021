import { getFilterPaidPartialUnpaidProps } from "../getCalculatationTypes";
import getFilterPaidAPI from "./getFilterPaidAPI";

const getFilterPaidServices = async (
  props: getFilterPaidPartialUnpaidProps
) => {
  return getFilterPaidAPI(props)
    .then((filterRecord) => {
      return filterRecord;
    })
    .catch((response) => {
      console.error(
        `getFilterPartnerPoliciesForPartnerAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getFilterPaidServices;
