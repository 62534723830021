import { debitHistoryPartnerEndpoint as endpoint } from "../apiEndpoints";
import { DebitPartnerProps } from "../getPartnersTypes";

const DebitHistoryAPI = async ({
  header,
  startDate,
  endDate,
  partnerId,
}: DebitPartnerProps) => {
  return fetch(endpoint(startDate!, endDate!, partnerId!), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default DebitHistoryAPI;
