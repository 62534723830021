import { MRT_ColumnDef } from "material-react-table";
import { IViewPolicy } from "../../../Policy/IPolicy";
import dayjs from "dayjs";
import { DAYJS_DISPLAY_FORMAT } from "../../../../context/constant";

const PartnerDebitColumns: MRT_ColumnDef<IViewPolicy>[] = [
  {
    accessorKey: "payOutCommission", //normal accessorKey
    header: "Commission",
    size: 100,
  },
  {
    accessorKey: "payOutAmount", //normal accessorKey
    header: "Paid Amount",
    size: 100,
  },
  {
    accessorKey: "payOutBalance", //normal accessorKey
    header: "balance",
    size: 100,
  },
  {
    accessorKey: "policyNumber", //normal accessorKey
    header: "Policy Number",
    size: 100,
  },
  {
    accessorKey: "od", //normal accessorKey
    header: "OD",
    size: 100,
  },
  {
    accessorKey: "tp", //normal accessorKey
    header: "TP",
    size: 100,
  },
  {
    accessorKey: "netPremium", //normal accessorKey
    header: "Net Premium",
    size: 100,
  },
  {
    accessorKey: "finalPremium", //normal accessorKey
    header: "Final Premium",
    size: 100,
  },
  {
    accessorKey: "payOutODPercentage", //normal accessorKey
    header: "PayOut OD%",
    size: 100,
  },
  {
    accessorKey: "payOutTPPercentage", //normal accessorKey
    header: "PayOut TP%",
    size: 100,
  },

  {
    header: "Created On",
    accessorKey: "createdOn",
    size: 50,
    Cell: ({ cell }) => {
      const dateValue = cell.getValue() as string | number | Date | null; // Explicitly cast to a valid type
      return dayjs(dateValue).format(DAYJS_DISPLAY_FORMAT);
    },
  },
]
export default PartnerDebitColumns