import addAccountAPI from "./addAccountAPI";
import { AddEditAccountProps } from "../getAccountTypes";

const addAccountService = async ({ header, account }: AddEditAccountProps) => {
  return addAccountAPI({
    header: header,
    account: account,
  })
    .then((newAccount) => {
      return newAccount;
    })
    .catch((response) => {
      console.error(
        `addAccountAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default addAccountService;
