import { getPolicyPDFEndpoint as endpoint } from "../apiEndpoints";
import { GetMotorPoliciesPDFProps } from "../getPoliciesTypes";

const motorPolicyPDFAPI = async ({ header, file }: GetMotorPoliciesPDFProps) => {
  return fetch(endpoint(), {
    method: "POST",
    body: file,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default motorPolicyPDFAPI;
