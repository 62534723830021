import getPolicyCompletedByIdAPI from "./getPolicyCompletedByIdAPI";
import { GetPolicyCompletedByIdProps } from "../getPoliciesTypes";

const getPolicyCompletedByIdService = async ({
  header,
  policyCompletedById,
  startDate,
  endDate,
}: GetPolicyCompletedByIdProps) => {
  return getPolicyCompletedByIdAPI({
    header: header,
    policyCompletedById: policyCompletedById,
    startDate,
    endDate,
  })
    .then((partnerMotorPolicies) => {
      return partnerMotorPolicies;
    })
    .catch((response) => {
      console.error(
        `getPolicyCompletedByIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getPolicyCompletedByIdService;
