import { editTeamEndpoint as endpoint } from "../apiEndpoints";
import { AddEditTeamProps } from "../getTeamsTypes";

const editTeamAPI = async ({ header, team, teamId }: AddEditTeamProps) => {
  return fetch(endpoint(teamId!), {
    method: "PUT",
    //headers: header,
    body: team,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default editTeamAPI;
