// const API_ENDPOINT_HOST: string =
//   process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const addAccountEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/account`);
export const getAccountsEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/account`);
export const editAccountEndpoint = (accountId: string) =>
  API_ENDPOINT_HOST.concat(`/api/account/${accountId}`);
export const getAccountsByIdEndpoint = (accountId: string) =>
  API_ENDPOINT_HOST.concat(`/api/account/${accountId}`);
export const getAccountsCreditDebitByIdEndpoint = (accountId: string) =>
  API_ENDPOINT_HOST.concat(`/api/account/account-details/${accountId}`);

export const getAccountDetailsByBrokerEndpoint = (
  startDate: string,
  endDate: string,
  brokerName: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/credit-debit/total-amount?startDate=${startDate}&endDate=${endDate}&brokerName=${brokerName}`
  );
export const getAccountDetailsByPartnerEndpoint = (
  startDate: string,
  endDate: string,
  partnerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/credit-debit/partner/total-amount?startDate=${startDate}&endDate=${endDate}&partnerId=${partnerId}`
  );
