import { deletePolicyEndpoint as endpoint } from "../apiEndpoints";
import { DeletePolicyProps } from "../getPoliciesTypes";

const deletePolicyAPI = async ({ header, policyId }: DeletePolicyProps) => {
  return fetch(endpoint(policyId!), {
    method: "DELETE",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default deletePolicyAPI;
