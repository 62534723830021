import { getBookingRequestbyBookingIdEndpoint as endpoint } from "../apiEndPoints";
import { GetBookingRequestByIdProps } from "../getBookingRequestTypes";

const getBookingRequestByBookingIdAPI = async ({
  header,
  bookingRequestId,
}: GetBookingRequestByIdProps) => {
  return fetch(endpoint(bookingRequestId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getBookingRequestByBookingIdAPI;
