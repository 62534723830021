//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const getFilterPartnerPoliciesForBrokerEndpoint = (
  startDate: string,
  endDate: string,
  brokerName: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/filter/broker-name?startDate=${startDate}&endDate=${endDate}&broker=${brokerName}`
  );
export const getFilterPaidPoliciesForPartnerEndpoint = (
  startDate: string,
  endDate: string,
  partnerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/payment/partner-id/paid-status?startDate=${startDate}&endDate=${endDate}&partnerId=${partnerId}`
  );

export const getFilterBrokerPoliciesForPartnerEndpoint = (
  startDate: string,
  endDate: string,
  partnerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/filter/partner-id?startDate=${startDate}&endDate=${endDate}&partnerId=${partnerId}`
  );
export const getFilterPaidPartialUnpaid = (
  startDate: string,
  endDate: string,
  partnerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/payment/partner-id/status?startDate=${startDate}&endDate=${endDate}&partnerId=${partnerId}`
  );

export const getFilterPaidPartialUnpaidForBroker = (
  startDate: string,
  endDate: string,
  brokerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/payment/broker-id/status?startDate=${startDate}&endDate=${endDate}&brokerId=${brokerId}`

  );
export const updateFilterPaymentsEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor/payment/status-manage`);
export const addBalanceInPartner = () =>
  API_ENDPOINT_HOST.concat(`/api/statement/manage`);
export const getFilterPaidPoliciesForBrokerEndpoint = (
  startDate: string,
  endDate: string,
  partnerId: string
) => API_ENDPOINT_HOST.concat(`/api/policy/motor/payment/broker-id/paid-status?brokerId=${partnerId}&startDate=${startDate}&endDate=${endDate}`)
