import { editAccountEndpoint as endpoint } from "../apiEndpoints";
import { AddEditAccountProps } from "../getAccountTypes";

const editAccountAPI = async ({
  header,
  account,
  accountId,
}: AddEditAccountProps) => {
  return fetch(endpoint(accountId!), {
    method: "PUT",
    headers: header,
    body: JSON.stringify({ ...account }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default editAccountAPI;
