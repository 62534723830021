// Lead API Endpoints
//const API_ENDPOINT_HOST: string =
// process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const addLeadEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate`);

export const editLeadEndpoint = (leadId: string) =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate/${leadId}`);

export const getLeadByIdEndpoint = (leadId: string) =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate/${leadId}`);

export const getLeadEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate`);

export const getLeadByPartnerIdEndpoint = (partnerId: string) =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate/partner-id/${partnerId}`);

export const getLeadByUserIdEndpoint = (userId: string) =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate/created-by/${userId}`);

export const acceptLeadEndpoint = (leadId: string) =>
  API_ENDPOINT_HOST.concat(`/api/lead-generate/accepted-lead/${leadId}`);
