import { addCreditDebitEndpoint as endpoint } from "../apiEndpoints";
import { AddEditCreditDebitProps } from "../getCreditDebitTypes";

const addCreditDebitAPI = async ({ header,creditDebit }: AddEditCreditDebitProps) => {
  return fetch(endpoint(), {
    method: "POST",
   headers: header,
   body: JSON.stringify({
    ...creditDebit,
  }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default addCreditDebitAPI;
