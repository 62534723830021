//const API_ENDPOINT_HOST: string =
// process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const addPolicyEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor`);
export const editPolicyEndpoint = (policyId: string) =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor/${policyId}`);
export const deletePolicyEndpoint = (policyId: string) =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor/${policyId}`);
export const archivePolicyEndpoint = (startDate: string, endDate: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/in-active?startDate=${startDate}&endDate=${endDate}`
  );

export const getPolicyEndpoint = (startDate: string, endDate: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/date-range?startDate=${startDate}&endDate=${endDate}`
  );
export const getPolicyByNumberEndpoint = (policyNumber: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/validatePolicyNumber/?policyNumber=${policyNumber}`
  );
export const getPolicyByPartnerEndpoint = (
  partnerId: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/partner/${partnerId}?startDate=${startDate}&endDate=${endDate}`
  );
export const getPolicyByIdEndpoint = (policyId: string) =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor/policyId/${policyId}`);
export const getPolicyByWithPaymentEndpoint = (policyId: string) =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor/payment-details/${policyId}`);
export const getPolicyCompletedByIdEndpoint = (
  policyCompletedById: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/policy-completed-by/${policyCompletedById}?startDate=${startDate}&endDate=${endDate}`
  );
export const getVechicleNumberEndpoint = (vehicleNumber: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/policy/motor/validateVehicleNumber?vehicleNumber=${vehicleNumber}`
  );

export const getPolicyDataByPolicyNumber = (policyNumber: string) => {
  return API_ENDPOINT_HOST.concat(
    `/api/policy/motor/policy-number/${policyNumber}`
  );
};
export const getPolicyDataByVehicleNumber = (vehicleNumber: string) => {
  return API_ENDPOINT_HOST.concat(
    `/api/policy/motor/vehicle-number/${vehicleNumber}`
  );
};
export const getPolicyPDFEndpoint = () => {
  return API_ENDPOINT_HOST.concat(`/api/policy/pdf/upload`);
};
export const motorPolicyExcelEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/policy/motor/upload`);