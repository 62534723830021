import { editBookingRequestEndpoint as endpoint } from "../apiEndPoints";
import { EditBookingRequestProps } from "../getBookingRequestTypes";

const editBookingRequestAPI = async ({
  header,
  bookingRequest,
  bookingId,
}: EditBookingRequestProps) => {
  return fetch(endpoint(bookingId), {
    method: "PUT",
    //headers: header,
    body: bookingRequest,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default editBookingRequestAPI;
