import { getFilterPaidPartialUnpaidForBroker as endpoint } from "../apiEndpoints";
import {  getFilterUnpaidPartialForBroker } from "../getCalculatationTypes";

const getFilterPoliciesForBrokerAPI = async (
  props: getFilterUnpaidPartialForBroker
) => {
  return fetch(endpoint(props.startDate!, props.endDate!, props.brokerId!), {
    method: "GET",
    headers: props.header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getFilterPoliciesForBrokerAPI;