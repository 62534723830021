import { updateFilterPaymentsEndpoint as endpoint } from "../apiEndpoints";
import { updateFilterPaymentsTypeProps } from "../getCalculatationTypes";

const updateFilterPaymentsAPI = async ({
  header,
  policyData,
}: updateFilterPaymentsTypeProps) => {
  return fetch(endpoint(), {
    method: "PUT",
    headers: header,
    body: JSON.stringify([...policyData!]),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default updateFilterPaymentsAPI;
