import { transactionHistoryPartnerEndpoint as endpoint } from "../apiEndpoints";
import { DebitTransactionProps } from "../getPartnersTypes";

const TransactionHistoryAPI = async ({
  header,
  transactionCode,
  partnerId,
}: DebitTransactionProps) => {
  return fetch(endpoint(transactionCode!, partnerId!), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default TransactionHistoryAPI;
