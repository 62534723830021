import { getPartnerPolicyDataEndpoint as endpoint } from "../apiEndPoints";
import { getPartnerCommissionProps } from "../getDashbaordTypes";

const GetPartnerPolicyDataAPI = async ({
  header,
  filter,
  partnerId,
}: getPartnerCommissionProps) => {
  return fetch(endpoint(partnerId, filter), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default GetPartnerPolicyDataAPI;
