import GetPartnerPolicyDataAPI from "./GetPartnerPolicyDataAPI";
import { getPartnerCommissionProps } from "../getDashbaordTypes";

const GetPartnerPolicyDataService = async ({
  header,
  filter,
  partnerId,
}: getPartnerCommissionProps) => {
  return GetPartnerPolicyDataAPI({
    header: header,
    filter: filter,
    partnerId: partnerId,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `GetPartnerPolicyDataAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetPartnerPolicyDataService;
