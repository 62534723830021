import getAccountDashboardAPI from "./getAccountDashboardAPI";
import { getAccountDashboardProps } from "../getDashbaordTypes";

const getAccountDashboardService = async ({
  header,
  startDate,
  endDate,
}: getAccountDashboardProps) => {
  return getAccountDashboardAPI({
    header: header,
    startDate: startDate,
    endDate: endDate,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `getAccountDashboardAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getAccountDashboardService;
