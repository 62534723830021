import getPartnerDashboardAPI from "./getPartnerDashboardAPI";
import { getPartnerDashboardProps } from "../getDashbaordTypes";

const getPartnerDashboardService = async ({
  header,
  partnerId,
  startDate,
  endDate,
}: getPartnerDashboardProps) => {
  return getPartnerDashboardAPI({
    header: header,
    partnerId: partnerId,
    startDate: startDate,
    endDate: endDate,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `getPartnerDashboardAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getPartnerDashboardService;
