import { getLeadByPartnerIdEndpoint as endpoint } from "../apiEndpoints";
import { GetLeadByPartnerIdProps } from "../getLeadsTypes";

const getLeadByPartnerIdAPI = async ({
  header,
  partnerId,
}: GetLeadByPartnerIdProps) => {
  return fetch(endpoint(partnerId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getLeadByPartnerIdAPI;
