import getAllEmployeesAPI from "./getAllEmployeeAPI";
import { GetEmployeesProps } from "../getTeamsTypes";

const getAllEmployeeService = async ({ header }: GetEmployeesProps) => {
  return getAllEmployeesAPI({
    header: header,
  })
    .then((teams) => {
      return teams;
    })
    .catch((response) => {
      console.error(`getEmployeesAPI failed with http status = ${response.status}`);
      return Promise.reject(response.json());
    });
};

export default getAllEmployeeService;
