import { getPolicyProps } from "../getDashbaordTypes";
import GetRevenueDataAPI from "./GetRevenueDataAPI";

const GetRevenueDataService = async ({ header, filter }: getPolicyProps) => {
    return GetRevenueDataAPI({
        header: header,
        filter: filter,
    })
        .then((dashboard) => {
            return dashboard;
        })
        .catch((response) => {
            console.error(
                `GetPolicyDataAPI failed with http status = ${response.status}`
            );
            return Promise.reject(response.json());
        });
};

export default GetRevenueDataService;