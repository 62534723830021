import acceptLeadAPI from "./acceptLeadAPI";
import { AcceptLeadsProps } from "../getLeadsTypes";

const acceptLeadService = async ({ header, lead }: AcceptLeadsProps) => {
  return acceptLeadAPI({
    header,
    lead,
  })
    .then((leadRecord) => {
      return leadRecord;
    })
    .catch((response) => {
      console.error(
        `acceptLeadAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default acceptLeadService;
