import React from "react";
import ProfileUi from "./ProfileUi";
import { IProfile } from "./IProfile";

const ProfilePage = () => {
  let storedTheme: any = localStorage.getItem("user") as IProfile | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;

  return (
    <>
      <ProfileUi {...UserData} />
    </>
  );
};

export default ProfilePage;
