import { getCommissionDataEndpoint as endpoint } from "../apiEndPoints";
import { getCommissionProps } from "../getDashbaordTypes";

const GetCommissionDataAPI = async ({
  header,filter
}: getCommissionProps) => {
  return fetch(endpoint(filter), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default GetCommissionDataAPI;
