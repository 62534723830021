
import { getFilterPaidPartialUnpaidProps } from "../getCalculatationTypes";
import getFilterUnpaidPartialAPI from "./getFilterUnpaidPartialAPI";

const getFilterUnpaidPartialServices = async (
  props: getFilterPaidPartialUnpaidProps
) => {
  return getFilterUnpaidPartialAPI(props)
    .then((filterRecord) => {
      return filterRecord;
    })
    .catch((response) => {
      console.error(
        `getFilterPartnerPoliciesForPartnerAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getFilterUnpaidPartialServices;
