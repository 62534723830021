import { editPolicyPaymentEndpoint as endpoint } from "../apiEndpoints";
import { EditPolicyPaymentProps } from "../getPoliciesPaymentTypes";

const editMotorPolicyPaymentAPI = async ({
  header,
  policyPayment,
}: EditPolicyPaymentProps) => {
  return fetch(endpoint(policyPayment.policyId!), {
    method: "PUT",
    headers: header,
    body: JSON.stringify({
      ...policyPayment,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default editMotorPolicyPaymentAPI;
