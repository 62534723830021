import { getVechicleNumberEndpoint as endpoint } from "../apiEndpoints";
import { GetVechicleNumberProps } from "../getPoliciesTypes";

const getVechicleNumberAPI = async ({
    header,
    vehicleNumber,
}: GetVechicleNumberProps) => {
    return fetch(endpoint(vehicleNumber), {
        method: "GET",
        headers: header,
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    });
};

export default getVechicleNumberAPI;
