import { getPolicyByPartnerEndpoint as endpoint } from "../apiEndpoints";
import { GetPolicyByPartnerIdProps } from "../getPoliciesTypes";

const getPolicyByPartnerIdAPI = async ({
  header,
  partnerId,
  startDate,
  endDate,
}: GetPolicyByPartnerIdProps) => {
  return fetch(endpoint(partnerId, startDate!, endDate!), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getPolicyByPartnerIdAPI;
