import getBookingRequestByBookingIdAPI from "./getBookingRequestByBookingIdAPI";
import { GetBookingRequestByIdProps } from "../getBookingRequestTypes";

const getBookingRequestByBookingIdService = async ({
  header,
  bookingRequestId,
}: GetBookingRequestByIdProps) => {
  return getBookingRequestByBookingIdAPI({
    header: header,
    bookingRequestId: bookingRequestId,
  })
    .then((bookingRequestes) => {
      return bookingRequestes;
    })
    .catch((response) => {
      console.error(
        `getBookingRequestByBookingIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getBookingRequestByBookingIdService;
