import { editCreditDebitEndpoint as endpoint } from "../apiEndpoints";
import { AddEditCreditDebitProps } from "../getCreditDebitTypes";

const editCreditDebitAPI = async ({
  header,
  creditDebit,
  creditDebitId,
}: AddEditCreditDebitProps) => {
  return fetch(endpoint(creditDebitId!), {
    method: "PUT",
    headers: header,
    body: JSON.stringify({
      ...creditDebit,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default editCreditDebitAPI;
