import { EditPolicyProps } from "../getPoliciesTypes";
import editPolicyAPI from "./editPolicyAPI";

const editPolicyService = async ({
  header,
  policy,
  policyId,
}: EditPolicyProps) => {
  return editPolicyAPI({
    header: header,
    policy: policy,
    policyId: policyId,
  })
    .then((newPolicy) => {
      return newPolicy;
    })
    .catch((response) => {
      console.error(
        `editPolicyAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default editPolicyService;
