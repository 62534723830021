import PayInExcelAPI from "./PayInExcelAPI";
import { GetExcelProps } from "../getUploadTypes";

const PayInExcelService = async ({ header, excel }: GetExcelProps) => {
  return PayInExcelAPI({
    header: header,
    excel: excel,
  })
    .then((newLead) => {
      return newLead;
    })
    .catch((response) => {
      console.error(
        `PayInExcelAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default PayInExcelService;
