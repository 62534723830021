import MotorPolicyExcelAPI from "./MotorPolicyExcelAPI";
import { GetMotorPolicyExcelProps } from "../getPoliciesTypes";

const MotorPolicyExcelService = async ({
  header,
  excel,
}: GetMotorPolicyExcelProps) => {
  return MotorPolicyExcelAPI({
    header: header,
    excel: excel,
  })
    .then((newLead) => {
      return newLead;
    })
    .catch((response) => {
      console.error(
        `MotorPolicyExcelAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default MotorPolicyExcelService;
