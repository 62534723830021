import validateModelAPI from "./validateModelAPI";
import { ValidateModelDetailsProps } from "../getModelsTypes";

const validateModelService = async ({
  header,
  modelName,
}: ValidateModelDetailsProps) => {
  return validateModelAPI({
    header: header,
    modelName: modelName,
  })
    .then((modelRecord) => {
      return modelRecord;
    })
    .catch((response) => {
      console.error(
        `validateModelAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default validateModelService;
