import { GetBookingRequestProps } from "../getBookingRequestTypes";
import GetBookingRequestByAdminAPI from "./GetBookingRequestByAdminAPI";

const GetBookingRequestByAdminService = async ({ header }: GetBookingRequestProps) => {
    return GetBookingRequestByAdminAPI({
        header: header,
    })
        .then((bookingRequests) => {
            return bookingRequests;
        })
        .catch((response) => {
            console.error(`getBookingRequestAPI failed with http status = ${response.status}`);
            return Promise.reject(response.json());
        });
};

export default GetBookingRequestByAdminService;