// const API_ENDPOINT_HOST: string =
//   process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const getAccountManageEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/account-manage`);
export const getAccountManageByAccountIdEndpoint = (accountId: string) =>
  API_ENDPOINT_HOST.concat(`/api/account-manage/account-id/${accountId}`);
export const getAccountManageByIdEndpoint = (accountId: string) =>
  API_ENDPOINT_HOST.concat(`/api/account-manage/${accountId}`);
export const addCreditDebitEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/credit-debit`);
export const addAccountManage = () =>
  API_ENDPOINT_HOST.concat(`/api/account-manage`);
export const getCreditDebitsEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/credit-debit`);
export const editCreditDebitEndpoint = (creditDebitId: string) =>
  API_ENDPOINT_HOST.concat(`/api/credit-debit/${creditDebitId}`);
export const getCreditDebitsByIdEndpoint = (creditDebitId: string) =>
  API_ENDPOINT_HOST.concat(`/api/credit-debit/${creditDebitId}`);
export const getCreditDebitsByBrokerEndpoint = (
  brokerId: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/account-manage/broker-id?brokerId=${brokerId}&startDate=${startDate}&endDate=${endDate}`
  );
  export const getAccountManageByPartnerDateRangeEndpoint = (
    partnerId: string,
    startDate: string,
    endDate: string
  ) =>
    API_ENDPOINT_HOST.concat(
      `/api/account-manage/partner-id?partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}`
    );
  
export const getCreditDebitsByPartnerEndpoint = (partnerId: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/credit-debit/partner-id?partnerId=${partnerId}`
  );
export const getCreditDebitsByPartnerDateRangeEndpoint = (
  partnerId: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/credit-debit/partner-id?partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}`
  );

export const getDebitDetailsEndpoint = (
  partnerId: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/statement/date-range?partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}`
  );
