import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { notificationPath } from '../sitemap';
import NotificationCard from '../components/Notification/NotificationCard';
interface NotificationBadgeProps {
  count: number;
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({ count }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewAll = () => {
    handleClose();
    navigate(notificationPath())
  };

  const handlePressNotification = (nId: string) => {
    console.log("nid", nId)
  }
  const notificationsLabel = (count: number) => {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  };

  const displayCount = Math.min(count, 4);

  return (
    <>
      <IconButton
        aria-label={notificationsLabel(count)}
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge badgeContent={count} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {count > 0 ? (
          <>
            {Array.from({ length: displayCount }, (_, index) => (
              <MenuItem key={index} onClick={handleClose}>
                <NotificationCard
                  title="New Update Available!"
                  message="We have a new version of the app ready for you. Check out the latest features."
                  onclick={handlePressNotification}
                />
              </MenuItem>
            ))}
            {count > 4 && <MenuItem sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={handleViewAll}>  <Typography variant="body2" color="textSecondary">
              View All
            </Typography></MenuItem>}
          </>
        ) : (
          <MenuItem disabled>No notifications</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default NotificationBadge;
