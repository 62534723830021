
import { GetVechicleNumberProps } from "../getPoliciesTypes";
import getPolicyDataByVehicleNumberAPI from "./getPolicyDataByVehicleNumberAPI";

const getPolicyDataByVehicleNumberService = async ({
    header,
    vehicleNumber,
}: GetVechicleNumberProps) => {
    return getPolicyDataByVehicleNumberAPI({
        header: header,
        vehicleNumber
    })
        .then((policy) => {
            return policy;
        })
        .catch((response) => {
            console.error(
                `getPolicyByNumberAPI failed with http status = ${response.status}`
            );
            return Promise.reject(response.json());
        });
};

export default getPolicyDataByVehicleNumberService;