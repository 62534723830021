import getCreditDebitByIdAPI from "./getCreditDebitByIdAPI";
import { GetCreditDebitByIdProps } from "../getCreditDebitTypes";
import convertICreditDebitToICreditDebitVM from "../convertICreditDebitToICreditDebitVM";

const getCreditDebitByIdService = async ({
  header,
  creditDebitId,
}: GetCreditDebitByIdProps) => {
  return getCreditDebitByIdAPI({
    header: header,
    creditDebitId: creditDebitId,
  })
    .then((creditDebits) => {
      const creditDebit = convertICreditDebitToICreditDebitVM(
        creditDebits.data
      );
      return creditDebit;
    })
    .catch((response) => {
      console.error(
        `getCreditDebitByIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getCreditDebitByIdService;
