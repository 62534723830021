import { getFilterPaidPartialUnpaid as endpoint } from "../apiEndpoints";
import { getFilterPaidPartialUnpaidProps } from "../getCalculatationTypes";

const getFilterUnpaidPartialAPI = async (
  props: getFilterPaidPartialUnpaidProps
) => {
  return fetch(endpoint(props.startDate!, props.endDate!, props.partnerId!), {
    method: "GET",
    headers: props.header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getFilterUnpaidPartialAPI;