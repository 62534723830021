import GetTotalBrokerPaymentAPI from "./GetTotalBrokerPaymentAPI";
import { getTotalBrokerPaymentProps } from "../getDashbaordTypes";

const GetTotalBrokerPaymentService = async ({
  header,
}: getTotalBrokerPaymentProps) => {
  return GetTotalBrokerPaymentAPI({
    header: header,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `GetTotalBrokerPaymentAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetTotalBrokerPaymentService;
