import getPolicyWithPaymentAPI from "./getPolicyWithPaymentAPI";
import { GetPolicyByIdProps } from "../getPoliciesTypes";

const getPolicyWithPaymentService = async ({
  header,
  policyId,
}: GetPolicyByIdProps) => {
  return getPolicyWithPaymentAPI({
    header: header,
    policyId: policyId,
  })
    .then((motorPolicies) => {
      return motorPolicies;
    })
    .catch((response) => {
      console.error(
        `getPolicyWithPaymentAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getPolicyWithPaymentService;
