import editCreditDebitAPI from "./editCreditDebitAPI";
import { AddEditCreditDebitProps } from "../getCreditDebitTypes";

const editCreditDebitService = async ({
  header,
  creditDebit,
  creditDebitId,
}: AddEditCreditDebitProps) => {
  return editCreditDebitAPI({
    header,
    creditDebit,
    creditDebitId,
  })
    .then((creditDebitRecord) => {
      return creditDebitRecord;
    })
    .catch((response) => {
      console.error(
        `editCreditDebitAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default editCreditDebitService;
