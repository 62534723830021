import { Typography, Paper, Grid } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { header } from "../../../context/constant";
import { useEffect, useState } from "react";
import { BrokerPayment } from "../IFilter";
import GetTotalBrokerPaymentService from "../../../api/Dashboard/GetTotalBrokerPayment/GetTotalBrokerPaymentService";

const FilterPayIn = () => {
  const title = "Get PayIn Details Of All Brokers";
  const [brokerTotalPayment, setBrokerTotalPayment] = useState<number>(0); // State for all credit debits
  const [brokerPayment, setBrokerPayment] = useState<BrokerPayment[]>([]); // State for all credit debits
  useEffect(() => {
    const fetchBrokerPayments = async () => {
      GetTotalBrokerPaymentService({
        header,
      }) // Call API to fetch credit debits
        .then((brokerPayment) => {
          // On successful API call
          setBrokerPayment(brokerPayment.data);
          setBrokerTotalPayment(brokerPayment.totalAmount);
        })
        .catch(async (error) => {
          const err = await error;
          toast.error(err.message);
        });
    };

    fetchBrokerPayments();
  }, []);

  return (
    <div className="bg-blue-200 p-7 mt-3">
      <Paper elevation={3} style={{ padding: 20 }}>
        <Typography variant="h5" className="text-black" gutterBottom>
          {title}{" "}
          <span className="text-safekaroDarkOrange">{brokerTotalPayment}</span>
        </Typography>

        <Grid container className="bg-blue-200 mt-3">
          {brokerPayment.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <div className="bg-white m-2 p-3 rounded-2xl shadow-lg flex items-center justify-between transform transition-transform duration-200 hover:scale-105">
                <div>
                  <Typography
                    variant="body2"
                    className="text-sm text-gray-600 mb-2"
                  >
                    {item.brokerName}
                  </Typography>
                  <Typography
                    variant="h5"
                    className="text-base font-bold text-gray-800"
                  >
                    {item.totalPayInCommission}
                  </Typography>
                </div>
                {/* Uncomment if needed
                <img src={icon} alt={title} className="h-8 w-8" />
                */}
              </div>
            </Grid>
          ))}
        </Grid>

        <Toaster position="bottom-center" reverseOrder={false} />
      </Paper>
    </div>
  );
};

export default FilterPayIn;
