import { partnerCompareExcelEndpoint as endpoint } from "../apiEndPoints";
import { GetPartnerExcelProps } from "../getCompareTypes";

const PartnerCompareExcelAPI = async ({
  header,
  excel
}: GetPartnerExcelProps) => {
  return fetch(endpoint(), {
    method: "POST",
    body: excel,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default PartnerCompareExcelAPI;
