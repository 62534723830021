import getAccountManageAPI from "./getAccountManageAPI";
import { GetManageAccountProps } from "../getCreditDebitTypes";

const getAccountManageService = async ({ header }: GetManageAccountProps) => {
  return getAccountManageAPI({
    header: header,
  })
    .then((accountRequest) => {
      return accountRequest;
    })
    .catch((response) => {
      console.error(
        `getAccountManageAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getAccountManageService;
