import { getPayOutCommissionDataEndpoint as endpoint } from "../apiEndPoints";
import { getPayOutCommissionProps } from "../getDashbaordTypes";

const GetPayOutCommissionAPI = async ({
  header,
  partnerId,
  filter,
}: getPayOutCommissionProps) => {
  return fetch(endpoint(partnerId, filter), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default GetPayOutCommissionAPI;
