import PartnerCompareExcelAPI from "./PartnerCompareExcelAPI";
import { GetPartnerExcelProps } from "../getCompareTypes";

const PartnerCompareExcelService = async ({
  header,
  excel
}: GetPartnerExcelProps) => {
  return PartnerCompareExcelAPI({
    header: header,
    excel: excel,

  })
    .then((newLead) => {
      return newLead;
    })
    .catch((response) => {
      console.error(
        `PartnerCompareExcelAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default PartnerCompareExcelService;
