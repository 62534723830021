import GetPolicyDataAPI from "./GetPolicyDataAPI";
import { getPolicyProps } from "../getDashbaordTypes";

const GetPolicyDataService = async ({ header, filter }: getPolicyProps) => {
  return GetPolicyDataAPI({
    header: header,
    filter: filter,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `GetPolicyDataAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetPolicyDataService;
