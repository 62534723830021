import { getPolicyByWithPaymentEndpoint as endpoint } from "../apiEndpoints";
import { GetPolicyByIdProps } from "../getPoliciesTypes";

const getPolicyWithPaymentAPI = async ({
  header,
  policyId,
}: GetPolicyByIdProps) => {
  return fetch(endpoint(policyId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getPolicyWithPaymentAPI;
