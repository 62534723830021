
import { GetBookingRequestProps } from "../getBookingRequestTypes";
import GetRejectedBookingRequestAPI from "./GetRejectedBookingRequestAPI";

const GetRejectedBookingRequestService = async ({ header }: GetBookingRequestProps) => {
  return GetRejectedBookingRequestAPI({
    header: header,
  })
    .then((bookingRequestes) => {
      return bookingRequestes;
    })
    .catch((response) => {
      console.error(`getBookingRequestAPI failed with http status = ${response.status}`);
      return Promise.reject(response.json());
    });
};

export default GetRejectedBookingRequestService;