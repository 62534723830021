import addCreditDebitAPI from "./addCreditDebitAPI";
import { AddEditCreditDebitProps } from "../getCreditDebitTypes";

const addCreditDebitService = async ({ header, creditDebit }: AddEditCreditDebitProps) => {
  return addCreditDebitAPI({
    header: header,
    creditDebit: creditDebit,
  })
    .then((newCreditDebit) => {
      return newCreditDebit;
    })
    .catch((response) => {
      console.error(
        `addCreditDebitAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default addCreditDebitService;
