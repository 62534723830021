import PayOutExcelAPI from "./PayOutExcelAPI";
import { GetExcelProps } from "../getUploadTypes";

const PayOutExcelService = async ({ header, excel }: GetExcelProps) => {
  return PayOutExcelAPI({
    header: header,
    excel: excel,
  })
    .then((newLead) => {
      return newLead;
    })
    .catch((response) => {
      console.error(
        `PayOutExcelAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default PayOutExcelService;
