import addCaseTypeAPI from "./addCaseTypeAPI";
import { AddEditCaseTypeProps } from "../getCaseTypes";

const addCaseTypeService = async ({ header, caseType }: AddEditCaseTypeProps) => {
  try {
    const newCaseType = await addCaseTypeAPI({
      header,
      caseType,
    });
    return newCaseType;
  } catch (response:any) {
    console.error(`addCaseTypeAPI failed with HTTP status`);
    return Promise.reject(response.json());
  }
};

export default addCaseTypeService;
