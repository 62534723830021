import { getCreditDebitsByIdEndpoint as endpoint } from "../apiEndpoints";
import { GetCreditDebitByIdProps } from "../getCreditDebitTypes";

const getCreditDebitByIdAPI = async ({
  header,
  creditDebitId,
}: GetCreditDebitByIdProps) => {
  return fetch(endpoint(creditDebitId), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getCreditDebitByIdAPI;
