import validateMakeAPI from "./validateMakeAPI";
import { ValidateMakeDetailsProps } from "../getMakesTypes";

const validateMakeService = async ({
  header,
  makeName,
}: ValidateMakeDetailsProps) => {
  return validateMakeAPI({
    header: header,
    makeName: makeName,
  })
    .then((makeRecord) => {
      return makeRecord;
    })
    .catch((response) => {
      console.error(
        `validateMakeAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default validateMakeService;
