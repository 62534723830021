import {  GetVechicleNumberProps } from "../getPoliciesTypes";
import getVechicleNumberAPI from "./getVechicleNumberAPI";

const getVechicleNumberService = async ({
  header,
  vehicleNumber,
}: GetVechicleNumberProps) => {
  return getVechicleNumberAPI({
    header: header,
    vehicleNumber
  })
    .then((policy) => {
      return policy;
    })
    .catch((response) => {
      console.error(
        `getPolicyByNumberAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getVechicleNumberService;
