//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
// const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const getDashboardEndpoint = (startDate: string, endDate: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/dashboard?startDate=${startDate}&endDate=${endDate}`
  );
export const getTotalPartnerPaymentEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/dashboard/partner-admin`);
export const getTotalBrokerPaymentEndpoint = () =>
  API_ENDPOINT_HOST.concat(`/api/dashboard/broker-admin`);
export const getMonthlyPartnerPaymentEndpoint = (
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/dashboard/partner-admin?startDate=${startDate}&endDate=${endDate}`
  );
export const getBarChartEndpoint = (timeframe: string) =>
  `${API_ENDPOINT_HOST}/api/user-profile/counts?timeframe=${timeframe}`;

export const getPartnerDashboardEndpoint = (
  partnerId: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/partner-dashboard?partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}`
  );

export const getBookingDashboardEndpoint = (
  bookingUserId: string,
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/booking-dashboard/${bookingUserId}?startDate=${startDate}&endDate=${endDate}`
  );
export const getOperationDashboardEndpoint = (operationUserId: string) =>
  API_ENDPOINT_HOST.concat(`/api/operation-dashboard/${operationUserId}`);
export const getAccountDashboardEndpoint = (
  startDate: string,
  endDate: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/account-dashboard?startDate=${startDate}&endDate=${endDate}`
  );

export const getCommissionDataEndpoint = (filter: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/admin-dashboard/commission?timeframe=${filter}`
  );
export const getPolicyDataEndpoint = (filter: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/admin-dashboard/policy-count?timeframe=${filter}`
  );
export const getPartnerPolicyDataEndpoint = (
  partnerId: string,
  filter: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/partner-dashboard/policy-count-partner?partnerId=${partnerId}&timeframe=${filter}`
  );
export const getPayInCommissionDataEndpoint = (
  partnerId: string,
  filter: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/broker-dashboard/payin-commission?brokerName=${partnerId}&timeframe=${filter}`
  );
export const getPayOutCommissionDataEndpoint = (
  partnerId: string,
  filter: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/partner-dashboard/payout-commission-partner?partnerId=${partnerId}&timeframe=${filter}`
  );

export const getPolicyRevenueEndpoint = (filter: string) =>
  API_ENDPOINT_HOST.concat(
    `/api/admin-dashboard/revenue-percentage?timeframe=${filter}`
  );
