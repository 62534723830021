import { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { accountsAddPath, accountCreditDebitViewPath } from "../../../sitemap";
import { IAccounts, IAccountsVM } from "../IAccounts";
import getAccountService from "../../../api/Account/GetAccount/getAccountService";
import { DAYJS_DISPLAY_FORMAT, header } from "../../../context/constant";
import toast, { Toaster } from "react-hot-toast";

const Accounts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<IAccounts[]>([]);
  const navigate = useNavigate();
  const handleAddAccountClick = () => {
    navigate(accountsAddPath());
  };
  const GetAccounts = useCallback(
    () =>
      getAccountService({ header })
        .then((accountsDetails) => {
          setAccounts(accountsDetails.data);
        })
        .catch(async(error) => {
          const err = await error
          toast.error(err.message)
          console.error("Failed to fetch product details", error);
        }),
    []
  );
  useEffect(() => {
    GetAccounts();
  }, [GetAccounts]);

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<IAccounts>[]>(
    () => [
      {
        accessorKey: "bankName", //normal accessorKey
        header: "Bank Name",
        size: 200,
      },
      {
        accessorKey: "accountCode", //normal accessorKey
        header: "Account Detail",
        size: 200,
      },
      {
        accessorKey: "amount", //normal accessorKey
        header: "Amount",
        size: 200,
      },
      {
        header: "Created On",
        accessorKey: "createdOn",
        size: 50,
      },
    ],
    []
  );

  const parsedData = useMemo(
    () =>
      accounts.map(
        (account: IAccounts) =>
          ({
            id: account._id,
            bankName: account.bankName,
            accountCode: account.accountCode,
            accountNumber: account.accountNumber,
            accountHolderName: account.accountHolderName,
            amount: account.amount,
            IFSCCode: account.IFSCCode,
            isActive: account.isActive,
            createdOn: dayjs(account.createdOn).format(DAYJS_DISPLAY_FORMAT),
            updatedOn: dayjs(account.updatedOn).format(DAYJS_DISPLAY_FORMAT),
          } as IAccountsVM)
      ) ?? [],
    [accounts]
  );

  const updateLoading = useCallback(async () => {
    // setIsLoading(true) when Accounts.length is 0, and setIsLoading(false) when Accounts.length is > 0
    setIsLoading(accounts.length >= 0 ? false : true);
  }, [accounts]);

  useEffect(() => {
    updateLoading();
  }, [updateLoading]);

  // Navigate to view page for a specific creditDebit
  const handleClickViewCreditDebit = (account: IAccountsVM) => {
    navigate(accountCreditDebitViewPath(account.id!)); // Navigate to edit page with specific creditDebit id
  };
  return (
    <>
      <div className="bg-blue-200 p-7">
        <Paper elevation={3} style={{ padding: 30 }}>
          <Typography className="text-safekaroDarkOrange" variant="h5">
            Accounts Table
          </Typography>
          <Typography variant="h5" mb={2}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <Link
                  to="/dashboard"
                  className="text-addButton font-bold text-sm"
                >
                  Dashboard /
                </Link>
                <span className="text-grey-600 text-sm"> Account</span>
              </div>
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                onClick={handleAddAccountClick}
              >
                Add Account
              </Button>
            </div>
            {/* Add a full-width grey line here */}
            <hr
              className="mt-4"
              style={{ width: "100%", borderColor: "grey-800" }}
            />
          </Typography>
          <MaterialReactTable
            state={{ isLoading }}
            columns={columns}
            data={parsedData}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row }) => (
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                {/* <Tooltip title={"Edit Account"}>
                  <IconButton
                    color="primary"
                    aria-label={"Edit Account"}
                    component="span"
                    onClick={() => {
                      handleClickEditAccount(row.original as IAccountsVM);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5 text-addButton"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </IconButton>
                </Tooltip> */}
                <Tooltip title={"View Account Details"}>
                  <IconButton
                    color="primary"
                    aria-label={"View Account Details"}
                    component="span"
                    onClick={() => {
                      handleClickViewCreditDebit(row.original as IAccountsVM);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6 text-safekaroDarkOrange"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>
              </div>
            )}
          />
        </Paper>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
    </>
  );
};

export default Accounts;
