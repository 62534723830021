import editBookingRequestAPI from "./editBookingRequestAPI";
import { EditBookingRequestProps } from "../getBookingRequestTypes";

const editBookingRequestService = async ({
  header,
  bookingRequest,
  bookingId,
}: EditBookingRequestProps) => {
  return editBookingRequestAPI({
    header,
    bookingRequest,
    bookingId,
  })
    .then((bookingRecord) => {
      return bookingRecord;
    })
    .catch((response) => {
      console.error(
        `editBookingRequestAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default editBookingRequestService;
