import { getBookingDashboardEndpoint as endpoint } from "../apiEndPoints";
import { getBookingDashboardProps } from "../getDashbaordTypes";

const getBookingDashboardAPI = async ({
  header,
  bookingUserId,
  startDate,
  endDate,
}: getBookingDashboardProps) => {
  return fetch(endpoint(bookingUserId, startDate, endDate), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default getBookingDashboardAPI;
