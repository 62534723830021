import { EditPolicyPaymentProps } from "../getPoliciesPaymentTypes";
import editMotorPolicyPaymentAPI from "./editMotorPolicyPaymentAPI";

const addMotorPolicyPaymentService = async ({
  header,
  policyPayment,
}: EditPolicyPaymentProps) => {
  return editMotorPolicyPaymentAPI({
    header: header,
    policyPayment: policyPayment,
  })
    .then((newPolicy) => {
      return newPolicy;
    })
    .catch((response) => {
      console.error(
        `editMotorPolicyPaymentAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default addMotorPolicyPaymentService;
