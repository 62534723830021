import { addBookingRequestEndpoint as endpoint } from "../apiEndPoints";
import { AddEditBookingRequestProps } from "../getBookingRequestTypes";

const addBookingRequestAPI = async ({
  bookingRequest,
}: AddEditBookingRequestProps) => {
  return fetch(endpoint(), {
    method: "POST",
    // headers: header,
    body: bookingRequest,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default addBookingRequestAPI;
