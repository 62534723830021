/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SafeKaroUser, header } from "../../context/constant";
import { Account, IAccountData } from "./IDashboard";
import MotorIcon from "../../assets/motor1.png";
import NetPremiumIcon from "../../assets/netPremium.png";
import FinalPremiumIcon from "../../assets/finalPremium.png";
import PayInCommissionIcon from "../../assets/payIn.png";
import PayOutCommissionIcon from "../../assets/payOut.png";
import TotalAccounts from "../../assets/bookedBooking.png";
import Accounts from "../../assets/totalLead.png";
import AdminCommisionChart from "./Chart/AdminCommisionChart";
import AdminPolicyChart from "./Chart/AdminPolicyChart";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Form } from "react-final-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import getAccountDashboardService from "../../api/Dashboard/GetAccountDashboard/getAccountDashboardService";
import { Link } from "react-router-dom";

const accountDashboard: React.FC = () => {
  const [data, setData] = useState<IAccountData[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [firstCart, setFirstCart] = useState(true);
  const [secondCart, setSecondCart] = useState(false);
  const [thirdCart, setThirdCart] = useState(false);
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;

  const GetDashboardCount = useCallback((startDate, endDate) => {
    getAccountDashboardService({
      header,
      startDate,
      endDate,
    })
      .then((dashboardData) => {
        setIsVisible(true);
        setData(dashboardData.data);
      })
      .catch((error) => {
        setIsVisible(true);
      });
  }, []);

  useEffect(() => {
    const currentDate = new Date(); // Example current date

    // Calculate first day of current month
    const firstDayOfMonth = startOfMonth(currentDate);

    // Calculate last day of current month
    const lastDayOfMonth = endOfMonth(currentDate);
    // Format the dates if needed
    const formattedFirstDay = format(firstDayOfMonth, "yyyy-MM-dd");
    const formattedLastDay = format(lastDayOfMonth, "yyyy-MM-dd");
    const fetchData = () => {
      GetDashboardCount(formattedFirstDay, formattedLastDay);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [GetDashboardCount]);

  const renderCountBox = (
    title: string,
    count: number,
    icon: string,
    path?: string
  ) => {
    const formattedCount = Math.round(count).toLocaleString(); // Format numbers with commas for readability

    const content = (
      <div className="bg-white m-2 p-3 rounded-2xl shadow-lg flex items-center justify-between transform transition-transform duration-200 hover:scale-105">
        <div>
          <Typography variant="body2" className="text-sm text-gray-600 mb-2">
            {title}
          </Typography>
          <Typography
            variant="h5"
            className="text-base font-bold text-gray-800"
          >
            {formattedCount}
          </Typography>
        </div>
        <img src={icon} alt={title} className="h-8 w-8" />
      </div>
    );

    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {path ? <Link to={path}>{content}</Link> : content}
      </Grid>
    );
  };

  const onSubmit = async (value: any) => {
    // Convert to local time zone
    // Create a Date object from the UTC date string
    const utcStartDate = new Date(value.startDate!);
    // Format the date
    const formattedStartDate = format(utcStartDate, "yyyy-MM-dd'T'HH:mm:ss");
    value.startDate = formattedStartDate;
    // Create a Date object from the UTC date string
    const utcEndDate = new Date(value.endDate!);
    const formattedEndDate = format(utcEndDate, "yyyy-MM-dd'T'HH:mm:ss");
    value.endDate = formattedEndDate;

    GetDashboardCount(value.startDate, value.endDate);
  };

  const handleFirstCart = async () => {
    setFirstCart(true);
    setSecondCart(false);
    setThirdCart(false);
  };
  const handleSecondCart = async () => {
    setFirstCart(false);
    setSecondCart(true);
    setThirdCart(false);
  };
  const handleThirdCart = async () => {
    setFirstCart(false);
    setSecondCart(false);
    setThirdCart(true);
  };

  return (
    <div className="bg-blue-200 p-7">
      <Card>
        <CardContent>
          <Grid container>
            <Grid item md={5}>
              <Typography variant="h6">
                Welcome {UserData.name.toUpperCase()}!
              </Typography>
            </Grid>
            <Grid item md={7} mb={3}>
              <Form
                onSubmit={onSubmit}
                // initialValues={initialValues}
                //validate={validate}
                render={({ handleSubmit, submitting, errors, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={input.value || null} // Initialize the value if it's undefined
                                onChange={(date) => {
                                  input.onChange(date);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    {...params}
                                    error={meta.touched && !!meta.error}
                                    helperText={meta.touched && meta.error}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Field>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Field name="endDate">
                          {({ input, meta }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={input.value || null} // Initialize the value if it's undefined
                                onChange={(date) => {
                                  input.onChange(date);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    {...params}
                                    error={meta.touched && !!meta.error}
                                    helperText={meta.touched && meta.error}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Field>
                      </Grid>
                      {/* Buttons for filtering partnerHistoryCard */}
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="w-4 h-4 bg-addButton text-white p-5 text-xs rounded-sm"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                            />
                          </svg>
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
            <Grid
              item
              md={3}
              className={`p-2 ${
                firstCart ? "bg-blue-200 shadow-md" : "bg-white"
              }`}
            >
              <Button type="button" onClick={handleFirstCart}>
                <Tooltip title={"View Policy Data"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25m-2.25 0h-2.25m0 0v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v4.964m12-4.006v4.006m0 0v3.75m-12-7.756v3.75m0 0h12m-12 0V14.25m12-4.5V9m0 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25m-2.25 0h-2.25m0 0v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v4.964m12-4.006v4.006m0 0v3.75m-12-7.756v3.75m0 0h12m-12 0V14.25m12-4.5V9"
                    />
                  </svg>
                </Tooltip>
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              className={`p-2 ${
                secondCart ? "bg-blue-200 shadow-md" : "bg-white"
              }`}
            >
              <Button type="button" onClick={handleSecondCart}>
                <Tooltip title={"View Partner Data"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                    />
                  </svg>
                </Tooltip>
              </Button>
            </Grid>
            <Grid
              item
              md={3}
              className={`p-2 ${
                thirdCart ? "bg-blue-200 shadow-md" : "bg-white"
              }`}
            >
              <Button type="button" onClick={handleThirdCart}>
                <Tooltip title={"View Chart"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                    />
                  </svg>
                </Tooltip>
              </Button>
            </Grid>

            <Grid item md={12}>
              {UserData.role.toLowerCase() === "account" ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {isVisible ? (
                        <>
                          {firstCart && (
                            <>
                              {data.map((item, index) => (
                                <div key={index}>
                                  <div className="bg-blue-200 p-7">
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Motor",
                                        item.policyCounts?.motor || 0,
                                        MotorIcon,"/policy/motorpolicies"
                                      )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "Net Premium",
                                        item.premiums?.["Net Premium"] || 0,
                                        NetPremiumIcon
                                      )}
                                      {renderCountBox(
                                        "Final Premium",
                                        item.premiums?.["Final Premium"] || 0,
                                        FinalPremiumIcon
                                      )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {renderCountBox(
                                        "PayIn Commission",
                                        item.commissions?.[
                                          "PayIn Commission"
                                        ] || 0,
                                        PayInCommissionIcon,
                                      )}
                                      {renderCountBox(
                                        "PayOut Commission",
                                        item.commissions?.[
                                          "PayOut Commission"
                                        ] || 0,
                                        PayOutCommissionIcon,
                                      )}
                                      {renderCountBox(
                                        "Broker Amount",
                                        item.commissions?.["Broker Amount"] ||
                                          0,
                                        PayOutCommissionIcon,"/account"
                                      )}
                                      {renderCountBox(
                                        "Broker Balance",
                                        item.commissions?.["Broker Balance"] ||
                                          0,
                                        PayOutCommissionIcon,"/account"
                                      )}
                                    </Grid>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                          {secondCart && (
                            <>
                              {data.map((item, index) => (
                                <div key={index}>
                                  <div className="bg-blue-200 p-7">
                                    <Grid container spacing={2}>
                                      {item.totalAccounts &&
                                        renderCountBox(
                                          "Total Accounts",
                                          item.totalAccounts,
                                          TotalAccounts,"/account"
                                        )}
                                      {item.totalAmount &&
                                        renderCountBox(
                                          "Total Amount",
                                          item.totalAmount,
                                          TotalAccounts,"/account"
                                        )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                      {item.accounts &&
                                        item.accounts.map(
                                          (account: Account, idx: number) =>
                                            renderCountBox(
                                              account.accountCode!,
                                              account.amount,
                                              Accounts,"/account"
                                            )
                                        )}
                                    </Grid>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                          {thirdCart && (
                            <div className="bg-blue-200 p-7">
                              <Grid container spacing={2}>
                                <Grid item md={6}>
                                  <AdminCommisionChart />
                                </Grid>
                                <Grid item md={6}>
                                  <AdminPolicyChart />
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </>
                      ) : (
                        <Typography variant="h6">Loading...</Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography variant="h6">Access Denied</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default accountDashboard;
