import GetPayOutCommissionAPI from "./GetPayOutCommissionAPI";
import { getPayOutCommissionProps } from "../getDashbaordTypes";

const GetPayOutCommissionService = async ({
  header,
  filter,
  partnerId,
}: getPayOutCommissionProps) => {
  return GetPayOutCommissionAPI({
    header: header,
    filter: filter,
    partnerId: partnerId,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `GetPayOutCommissionAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetPayOutCommissionService;
