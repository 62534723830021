import { getFilterUnpaidPartialForBroker } from "../getCalculatationTypes";
import GetFilterPaidForBrokerAPI from "./GetFilterPaidForBrokerAPI";

const getFilterPaidForBrokerService = async (
  props: getFilterUnpaidPartialForBroker
) => {
  return GetFilterPaidForBrokerAPI(props)
    .then((filterRecord) => {
      return filterRecord;
    })
    .catch((response) => {
      console.error(
        `getFilterPartnerPoliciesForPartnerAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getFilterPaidForBrokerService;