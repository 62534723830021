import GetCreditDebitByPartnerByDateRangeAPI from "./GetCreditDebitByPartnerByDateRangeAPI";
import { GetCreditDebitByPartnerDateRangeProps } from "../getCreditDebitTypes";
//import convertICreditDebitToICreditDebitVM from "../convertICreditDebitToICreditDebitVM";

const GetCreditDebitByPartnerByDateRangeService = async ({
  header,
  partnerId,
  startDate,
  endDate,
}: GetCreditDebitByPartnerDateRangeProps) => {
  return GetCreditDebitByPartnerByDateRangeAPI({
    header: header,
    partnerId: partnerId,
    startDate: startDate,
    endDate: endDate,
  })
    .then((creditDebits) => {

      return creditDebits;
    })
    .catch((response) => {
      console.error(
        `GetCreditDebitByPartnerByDateRangeAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default GetCreditDebitByPartnerByDateRangeService;
