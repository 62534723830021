import { editPolicyEndpoint as endpoint } from "../apiEndpoints";
import { EditPolicyProps } from "../getPoliciesTypes";

const editPolicyAPI = async ({ header, policy, policyId }: EditPolicyProps) => {
  return fetch(endpoint(policyId!), {
    method: "PUT",
    // headers: header,
    body: policy,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default editPolicyAPI;
