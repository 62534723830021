import getOperationDashboardAPI from "./GetOperationDashboardAPI";
import { getOperationDashboardProps } from "../getDashbaordTypes";

const getOperationDashboardService = async ({
  header,
  operationUserId,
}: getOperationDashboardProps) => {
  return getOperationDashboardAPI({
    header: header,
    operationUserId: operationUserId,
  })
    .then((dashboard) => {
      return dashboard;
    })
    .catch((response) => {
      console.error(
        `getOperationDashboardAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getOperationDashboardService;
