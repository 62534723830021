import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import routes from "./routes";
import "./App.css";
import Sidebar from "./components/Sidebar/sidebar";
import PartnerSidebar from "./components/Sidebar/partnerSideBar";
import BookingSidebar from "./components/Sidebar/bookingSidebar";
import OperationSidebar from "./components/Sidebar/operationSideBar";
import AccountSidebar from "./components/Sidebar/accountSideBar";
import Header from "./components/Header/header";
import { SafekaroProvider } from "./context/SafeKaroContext";
import { SafeKaroUser } from "./context/constant";
import { useEffect, useState } from "react"; // Import useState for local state

function App() {
  const content = useRoutes(routes);
  const location = useLocation();
  const currentUrl = location.pathname;
  const navigate = useNavigate()
  // Using useState for userData and storedTheme
  const [userData, setUserData] = useState<SafeKaroUser>();

  useEffect(() => {
    let storedTheme = localStorage.getItem("user");
    if (storedTheme) {
      let parsedUserData = JSON.parse(storedTheme) as SafeKaroUser;
      setUserData(parsedUserData);
    }
  }, []); // Empty dependency array means this effect runs only once
  useEffect(() => {

    const isPolicyPage = currentUrl.includes('/policy')
    if (!isPolicyPage) {
      sessionStorage.clear()
    }
    if (currentUrl !== "/") {
      let storedTheme = localStorage.getItem("user");
      if (storedTheme) {
        let parsedUserData = JSON.parse(storedTheme) as SafeKaroUser;
        setUserData(parsedUserData);
      } else {
        navigate("/")
      }
    }
  }, [currentUrl]); // Empty dependency array means this effect runs only once


  return (
    <>
      <SafekaroProvider>
        {currentUrl === "/" ||
          currentUrl === "/signup" ||
          currentUrl === "/403" ? (
          <> {content}</>
        ) : (
          <>
            {userData ? (
              <div className="flex h-screen bg-gray-100">
                {userData.role.toLowerCase() === "admin" && <Sidebar />}
                {userData.role.toLowerCase() === "partner" && (
                  <PartnerSidebar />
                )}
                {userData.role.toLowerCase() === "booking" && (
                  <BookingSidebar />
                )}
                {userData.role.toLowerCase() === "operation" && (
                  <OperationSidebar />
                )}
                {userData.role.toLowerCase() === "account" && (
                  <AccountSidebar />
                )}
                <div className="flex flex-col flex-1 overflow-y-auto">
                  <Header />
                  {content}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </SafekaroProvider>
    </>
  );
}

export default App;
