// const API_ENDPOINT_HOST: string =
//   process.env.REACT_APP_SAFE_KARO_ADMIN_API_ENDPOINT || "";
//const API_ENDPOINT_HOST = "http://localhost:8000";
const API_ENDPOINT_HOST = "https://api.safekaro.com";
//const API_ENDPOINT_HOST = "https://test.safekaro.com";

export const getPartnerEndpoint = (role: string) =>
  API_ENDPOINT_HOST.concat(`/api/user-profile/byRole?role=${role}`);

export const historyPartnerEndpoint = (partnerId: string) =>
  API_ENDPOINT_HOST.concat(`/api/statement/manage/${partnerId}`);

export const debitHistoryPartnerEndpoint = (
  startDate: string,
  endDate: string,
  partnerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/debit/date-range/${partnerId}?startDate=${startDate}&endDate=${endDate}`
  );
export const transactionHistoryPartnerEndpoint = (
  transactionCode: string,
  partnerId: string
) =>
  API_ENDPOINT_HOST.concat(
    `/api/debit/transaction?partnerId=${partnerId}&transactionCode=${transactionCode}`
  );
