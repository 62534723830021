import deletePolicyAPI from "./deletePolicyAPI";
import { DeletePolicyProps } from "../getPoliciesTypes";

const deletePolicyService = async ({
  header,
  policyId,
  policies,
}: DeletePolicyProps) => {
  return deletePolicyAPI({
    header,
    policyId,
    policies,
  })
    .then(() => {
      const deletedPolicyIndex = policies.findIndex(
        (policy: any) => policy._id === policyId
      );
      //Remove this Why becasue i dont want to call Get API in display layer
      policies.splice(deletedPolicyIndex, 1);
      return policies;
    })
    .catch((response) => {
      console.error(
        `deletePolicyAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default deletePolicyService;
