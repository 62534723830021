import NotificationCard from './NotificationCard';
import { Box, Container } from '@mui/material';

const Notification = () => {
  const handlePressNotification = (nId: string) => {
    console.log("id", nId)
  }
  return (
    <Container sx={{ marginTop: 5 }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Array.from({ length: 5 }).map((_, index) => (
        <Box key={index} sx={{ width: '100%' }}>
          <NotificationCard
            title="New Update Available!"
            message="We have a new version of the app ready for you. Check out the latest features."
            onclick={handlePressNotification}
          />
        </Box>
      ))}
    </Box>
  </Container>
  );
};

export default Notification;
