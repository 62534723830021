import { brokerCompareExcelEndpoint as endpoint } from "../apiEndPoints";
import { GetBrokerExcelProps } from "../getCompareTypes";

const PayInExcelAPI = async ({
  header,
  excel
}: GetBrokerExcelProps) => {
  return fetch(endpoint(), {
    method: "POST",
    body: excel,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default PayInExcelAPI;
