
import { AddEditCreditDebitProps } from "../getCreditDebitTypes";
import addAccountManageAPI from "./AddAccountManageAPI";

const addAccountManageService = async ({ header, creditDebit }: AddEditCreditDebitProps) => {
  return addAccountManageAPI({
    header: header,
    creditDebit: creditDebit,
  })
    .then((newCreditDebit) => {
      return newCreditDebit;
    })
    .catch((response) => {
      console.error(
        `addCreditDebitAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default addAccountManageService;