import { getCreditDebitsByBrokerEndpoint as endpoint } from "../apiEndpoints";
import { GetCreditDebitByBrokerProps } from "../getCreditDebitTypes";

const GetCreditDebitByBrokerAPI = async ({
  header,
  brokerId,startDate, endDate
}: GetCreditDebitByBrokerProps) => {
  return fetch(endpoint(brokerId, startDate, endDate), {
    method: "GET",
    headers: header,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default GetCreditDebitByBrokerAPI;
