import getBookingRequestByPartnerIdAPI from "./getBookingRequestByPartnerIdAPI";
import { GetBookingRequestByPartnerIdProps } from "../getBookingRequestTypes";

const getBookingRequestByPartnerIdService = async ({
  header,
  partnerId,
}: GetBookingRequestByPartnerIdProps) => {
  return getBookingRequestByPartnerIdAPI({
    header: header,
    partnerId: partnerId,
  })
    .then((bookingRequestes) => {
      return bookingRequestes;
    })
    .catch((response) => {
      console.error(
        `getBookingRequestByPartnerIdAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getBookingRequestByPartnerIdService;
