import BrokerCompareExcelAPI from "./BrokerCompareExcelAPI";
import { GetBrokerExcelProps } from "../getCompareTypes";

const BrokerCompareExcelService = async ({
  header,
  excel,
}: GetBrokerExcelProps) => {
  return BrokerCompareExcelAPI({
    header: header,
    excel: excel,
  })
    .then((newLead) => {
      return newLead;
    })
    .catch((response) => {
      console.error(
        `BrokerCompareExcelAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default BrokerCompareExcelService;
