import { getFilterUnpaidPartialForBroker } from "../getCalculatationTypes";
import getFilterPoliciesForBrokerAPI from "./getFilterPoliciesForBrokerAPI";



const getFilterPoliciesForBrokerService = async (
  props: getFilterUnpaidPartialForBroker
) => {
  return getFilterPoliciesForBrokerAPI(props)
    .then((filterRecord) => {
      return filterRecord;
    })
    .catch((response) => {
      console.error(
        `getFilterPartnerPoliciesForPartnerAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default getFilterPoliciesForBrokerService;