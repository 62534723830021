//import async from "./components/Async";
import SignUp from "./Auth/signup";
import Dashboard from "./components/Dashboard/dashboard";
import PartnerDashboard from "./components/Dashboard/partnerDashboard";
import BookingDashboard from "./components/Dashboard/bookingDashboard";
import Roles from "./components/Admin/Role/Roles/Roles";
import AddRole from "./components/Admin/Role/AddRole/addRole";
import Products from "./components/Admin/Product/Products/Products";
import AddProduct from "./components/Admin/Product/AddProduct/addProduct";
import AddPolicyType from "./components/Admin/PolicyType/AddPolicyType/AddPolicyType";
import PolicyTypes from "./components/Admin/PolicyType/PolicyTypes/PolicyTypes";
import CaseTypes from "./components/Admin/CaseType/CaseTypes/CaseTypes";
import AddCaseType from "./components/Admin/CaseType/AddCaseType/addCaseType";
import AddMotorPolicy from "./components/Policy/AddPolicy/AddPolicy";
import EditMotorPolicy from "./components/Policy/EditPolicy/EditPolicy";
import Signin from "./Auth/signin";
import GetMotorPolicies from "./components/Policy/GetMotorPolicies/GetMotorPolicies";
import PayInUploadByExcel from "./components/Admin/Percentage/PayInUploadByExcel/PayInUploadByExcel";
import PayOutUploadByExcel from "./components/Admin/Percentage/PayOutUploadByExcel/payOutUploadByExcel";
import AddProductSubType from "./components/Admin/ProductSubType/AddProductSubType/AddProductSubType";
import ProductSubTypes from "./components/Admin/ProductSubType/ProductSubTypes/ProductSubTypes";
import AddCompany from "./components/Admin/Company/AddCompany/addCompany";
import Companies from "./components/Admin/Company/Companies/Companies";
import Brokers from "./components/Admin/Broker/Brokers/Brokers";
import AddBroker from "./components/Admin/Broker/AddBroker/addBroker";
import AddBranch from "./components/Admin/Branch/AddBranch/addBranch";
import Branches from "./components/Admin/Branch/Branches/Branches";
import Categories from "./components/Admin/Category/Categories/Categories";
import AddCategory from "./components/Admin/Category/AddCategory/AddCategory";
import AddFuelType from "./components/Admin/FuelType/AddFuelType/addFuelType";
import FuelTypes from "./components/Admin/FuelType/FuelTypes/FuelTypes";
import AddMake from "./components/Admin/Make/AddMake/addMake";
import Makes from "./components/Admin/Make/Makes/Makes";
import Models from "./components/Admin/Model/Models/Models";
import AddModel from "./components/Admin/Model/AddModel/addModel";
import ViewPolicy from "./components/Policy/ViewPolicy/ViewPolicy";
import Teams from "./components/Admin/Team/Teams/Teams";
import AddTeam from "./components/Admin/Team/AddTeam/AddTeam";
import Page403 from "./Auth/Page403";
//import AuthGuards from "./guards/AuthGuards";
import AddBookingRequest from "./components/Booking/AddBookingRequest/AddBookingRequest";
import BookingRequests from "./components/Booking/BookingRequests/BookingRequests";
import AddLead from "./components/Partner/AddLead/AddLead";
import Leads from "./components/Partner/Leads/leads";
import NewLeads from "./components/Partner/Leads/Newleads";
import EditLead from "./components/Partner/EditLead/editLead";
import AddQuotation from "./components/Partner/Quotation/AddQuotation/AddQuotation";
import ViewQuotation from "./components/Partner/Quotation/ViewQuotation/ViewQuotation";
import NewBookingRequests from "./components/Booking/BookingRequests/NewBookingRequests";
import EditCommison from "./components/Policy/EditCommsion/editCommison";
import AddAccounts from "./components/Account/AddAcoounts/AddAccounts";
import Accounts from "./components/Account/Accounts/Accounts";
import CompareBrokerPayment from "./components/Account/CompareBrokerPayment/compareBrokerPayment";
import CreditDebits from "./components/Account/CreditDebit/CreditDebits/CreditDebits";
import AddCreditDebits from "./components/Account/CreditDebit/AddCreditDebit/AddCreditDebit";
import AccountDashboard from "./components/Dashboard/accountDashboard";
import UpdatePayment from "./components/Account/UpdatePayments/ManageBrokerPayment/ManageBrokerPayment";
import ManagePartnerPayment from "./components/Account/UpdatePayments/ManagePartnerPayment/ManagePartnerPayment";
import ComparePartnerPayment from "./components/Account/ComparePartnerPayment/comparePartnerPayment";
import ViewAccountCreditDebitsDetails from "./components/Account/Accounts/ViewAccountDetails/ViewAccountCreditDebitsDetails";
import ViewCreditDebitByBrokerCard from "./components/Account/CreditDebit/ViewCreditDebitByBroker/ViewCreditDebitByBrokerCard";
import ViewCreditDebitByPartnerCard from "./components/Account/CreditDebit/ViewCreditDebitByPartner/ViewCreditDebitByPartnerCard";
import Percentage from "./components/Admin/Percentage/Percentage/Percentage";
import ManageCards from "./components/Partner/ManageCard/manageCards";
import ViewCardHistory from "./components/Partner/ManageCard/ViewCardHistory";
import PartnerDebit from "./components/Account/UpdatePayments/PartnerDebit/PartnerDebit";
import ViewPolicyDetails from "./components/Policy/ViewPolicy/ViewPolicyDetails";
import OperationDashboard from "./components/Dashboard/operationDashboard";
import BrokerCredit from "./components/Account/UpdatePayments/BrokerCredit/BrokerCredit";
import GetArchiveMotorPolicies from "./components/Policy/GetMotorPolicies/GetArchiveMotorPolicies";
import RejectionPolicies from "./components/Booking/BookingRequests/RejectionPolicies";
import Partners from "./components/Admin/Team/Teams/Partners";
import Operations from "./components/Admin/Team/Teams/Operations";
import RM from "./components/Admin/Team/Teams/RM";
import Bookings from "./components/Admin/Team/Teams/Bookings";
import TeamAccounts from "./components/Admin/Team/Teams/Accounts";
import Filter from "./components/Filter/FilterPolicy/Filter";
import Notification from "./components/Notification/Notification";
import PolicyPDF from "./components/Policy/PolicyPDF/PolicyPDF";
import ProfilePage from "./components/Profile/ProfilePage";
import AcceptedBooking from "./components/Booking/AcceptedBooking/AcceptedBooking";
import UploadPolicy from "./components/Policy/UploadPolicy/UploadPolicy";
import FilterPayOut from "./components/Filter/FilterPayOut/FilterPayOut";
import FilterPayIn from "./components/Filter/FilterPayIn/FilterPayIn";
//import Roles from "./components/Role/Roles/Roles";
//import AdminLayout from "./layouts/admin";
// ----------------Dashboard components-----------
// const Roles = async(() => import("./components/Role/Roles/Roles"));
// const Dashboard = async(() => import("./components/Dashboard/dashboard"));

const routes = [
  {
    path: "/",
    children: [
      {
        path: "",
        element: <Signin />,
      },
    ],
  },
  {
    path: "/signup",
    children: [
      {
        path: "",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/rm",
    children: [
      {
        path: "",
        element: <RM />,
      },
    ],
  },
  {
    path: "/team-booking",
    children: [
      {
        path: "",
        element: <Bookings />,
      },
    ],
  },
  {
    path: "/accounts",
    children: [
      {
        path: "",
        element: <TeamAccounts />,
      },
    ],
  },
  {
    path: "/partners",
    children: [
      {
        path: "",
        element: <Partners />,
      },
    ],
  },
  {
    path: "/operations",
    children: [
      {
        path: "",
        element: <Operations />,
      },
    ],
  },
  {
    path: "dashboard",
    // element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "lead",
    //element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Leads />,
      },
      {
        path: "new",
        element: <NewLeads />,
      },
      {
        path: "add",
        element: <AddLead />,
      },
      {
        path: "/lead/:leadId/edit",
        element: <EditLead />,
      },
      {
        path: "/lead/:leadId/quotation",
        element: <AddQuotation />,
      },
      {
        path: "/lead/:leadId/quotation/view",
        element: <ViewQuotation />,
      },
    ],
  },
  {
    path: "role",
    // element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Roles />,
      },
      {
        path: "add",
        element: <AddRole />,
      },
      {
        path: "/role/:roleId/edit",
        element: <AddRole />,
      },
    ],
  },
  {
    path: "profile",
    children: [
      {
        path: "",
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "payins",
    children: [
      {
        path: "",
        element: <FilterPayIn />,
      },
    ],
  },
  {
    path: "payouts",
    children: [
      {
        path: "",
        element: <FilterPayOut />,
      },
    ],
  },
  {
    path: "account",
    // element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Accounts />,
      },
      {
        path: "/account/partner-debit",
        element: <PartnerDebit />,
      },
      {
        path: "/account/broker-credit",
        element: <BrokerCredit />,
      },
      {
        path: "/account/broker-payment-compare",
        element: <CompareBrokerPayment />,
      },
      {
        path: "/account/partner-payment-compare",
        element: <ComparePartnerPayment />,
      },
      {
        path: "creditdebit",
        element: <CreditDebits />,
      },
      {
        path: "/account/creditdebit/broker-payment/view",
        element: <ViewCreditDebitByBrokerCard />,
      },
      {
        path: "/account/creditdebit/partner-payment/view",
        element: <ViewCreditDebitByPartnerCard />,
      },
      {
        path: "/account/creditdebit/:accountId/view",
        element: <ViewAccountCreditDebitsDetails />,
      },
      {
        path: "/account/creditdebit/add",
        element: <AddCreditDebits />,
      },
      {
        path: "/account/creditdebit/:creditDebitId/edit",
        element: <AddCreditDebits />,
      },
      {
        path: "add",
        element: <AddAccounts />,
      },
      {
        path: "/account/:accountId/edit",
        element: <AddAccounts />,
      },
      {
        path: "/account/broker/update-payment",
        element: <UpdatePayment />,
      },
      {
        path: "/account/partner/update-payment",
        element: <ManagePartnerPayment />,
      },
    ],
  },
  {
    path: "booking",
    // element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <BookingRequests />,
      },
      {
        path: "new",
        element: <NewBookingRequests />,
      },
      {
        path: "reject",
        element: <RejectionPolicies />,
      },
      {
        path: "add",
        element: <AddBookingRequest />,
      },
      {
        path: "accepted",
        element: <AcceptedBooking />,
      },
      {
        path: "/booking/:leadId/new",
        element: <AddBookingRequest />,
      },
    ],
  },
  {
    path: "policytype",
    // element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <PolicyTypes />,
      },
      {
        path: "add",
        element: <AddPolicyType />,
      },
      {
        path: "/policytype/:policyTypeId/edit",
        element: <AddPolicyType />,
      },
    ],
  },
  {
    path: "policy",
    //element: <AdminLayout />,
    children: [
      {
        path: "/policy/motor/upload",
        element: <PolicyPDF />,
      },
      {
        path: "/policy/motor/excel/upload",
        element: <UploadPolicy />,
      },
      {
        path: "/policy/motor/add",
        element: <AddMotorPolicy />,
      },
      {
        path: "/policy/motor/:bookingRequestId",
        element: <EditMotorPolicy />,
      },
      {
        path: "/policy/:policyId/view",
        element: <ViewPolicy />,
      },
      {
        path: "/policy/:policyId",
        element: <ViewPolicyDetails />,
      },
      {
        path: "/policy/:policyId/edit",
        element: <AddMotorPolicy />,
      },
      {
        path: "/policy/:policyId/commission",
        element: <EditCommison />,
      },
      {
        path: "motorpolicies",
        element: <GetMotorPolicies />,
      },
      {
        path: "archive",
        element: <GetArchiveMotorPolicies />,
      },
    ],
  },
  {
    path: "team",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Teams />,
      },
      {
        path: "add",
        element: <AddTeam />,
      },
      {
        path: "/team/:teamId/edit",
        element: <AddTeam />,
      },
    ],
  },
  {
    path: "subproducts",
    // element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <ProductSubTypes />,
      },
      {
        path: "add",
        element: <AddProductSubType />,
      },
      {
        path: "/subproducts/:productSubTypeId/edit",
        element: <AddProductSubType />,
      },
      {
        path: "/subproducts/add/:productId",
        element: <AddProductSubType />,
      },
    ],
  },
  {
    path: "casetype",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <CaseTypes />,
      },
      {
        path: "add",
        element: <AddCaseType />,
      },
      {
        path: "/casetype/:caseTypeId/edit",
        element: <AddCaseType />,
      },
    ],
  },
  {
    path: "fueltype",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <FuelTypes />,
      },
      {
        path: "add",
        element: <AddFuelType />,
      },
      {
        path: "/fueltype/:fuelTypeId/edit",
        element: <AddFuelType />,
      },
    ],
  },
  {
    path: "commision",
    // element: <AdminLayout />,
    children: [
      {
        path: "payout",
        element: <PayOutUploadByExcel />,
      },
      {
        path: "payin",
        element: <PayInUploadByExcel />,
      },
      {
        path: "percentage",
        element: <Percentage />,
      },
    ],
  },
  {
    path: "calculate",
    // element: <AdminLayout />,
    children: [],
  },
  {
    path: "products",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Products />,
      },
      {
        path: "add",
        element: <AddProduct />,
      },
      {
        path: "/products/:productId/edit",
        element: <AddProduct />,
      },
    ],
  },
  {
    path: "companies",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Companies />,
      },
      {
        path: "add",
        element: <AddCompany />,
      },
      {
        path: "/companies/:companyId/edit",
        element: <AddCompany />,
      },
    ],
  },
  {
    path: "brokers",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Brokers />,
      },
      {
        path: "add",
        element: <AddBroker />,
      },
      {
        path: "/brokers/:brokerId/edit",
        element: <AddBroker />,
      },
    ],
  },
  {
    path: "branch",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Branches />,
      },
      {
        path: "add",
        element: <AddBranch />,
      },
      {
        path: "/branch/:branchId/edit",
        element: <AddBranch />,
      },
    ],
  },
  {
    path: "categories",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Categories />,
      },
      {
        path: "add",
        element: <AddCategory />,
      },
      {
        path: "/categories/:categoryId/edit",
        element: <AddCategory />,
      },
    ],
  },
  {
    path: "models",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Models />,
      },
      {
        path: "add",
        element: <AddModel />,
      },
      {
        path: "/models/:modelId/edit",
        element: <AddModel />,
      },
    ],
  },
  {
    path: "makes",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Makes />,
      },
      {
        path: "add",
        element: <AddMake />,
      },
      {
        path: "/makes/:makeId/edit",
        element: <AddMake />,
      },
    ],
  },
  {
    path: "accountdashboard",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <AccountDashboard />,
      },
    ],
  },
  {
    path: "operationdashboard",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <OperationDashboard />,
      },
    ],
  },
  {
    path: "partnerdashboard",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <PartnerDashboard />,
      },
      {
        path: "/partnerdashboard/card",
        element: <ViewCardHistory />,
      },
      {
        path: "/partnerdashboard/withdrawal",
        element: <ManageCards />,
      },
      {
        path: "/partnerdashboard/withdrawal/:partnerId",
        element: <ManageCards />,
      },
      {
        path: "/partnerdashboard/:transactionCode/:partnerId/:startDate/:endDate/card-history",
        element: <ViewCardHistory />,
      },
    ],
  },
  {
    path: "bookingdashboard",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <BookingDashboard />,
      },
    ],
  },
  {
    path: "403",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Page403 />,
      },
    ],
  },
  {
    path: "filter",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Filter />,
      },
    ],
  },
  {
    path: "notification",
    //element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Notification />,
      },
    ],
  },
];

export default routes;
