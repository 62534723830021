import editAccountAPI from "./editAccountAPI";
import { AddEditAccountProps } from "../getAccountTypes";

const editAccountService = async ({
  header,
  account,
  accountId,
}: AddEditAccountProps) => {
  return editAccountAPI({
    header,
    account,
    accountId,
  })
    .then((accountRecord) => {
      return accountRecord;
    })
    .catch((response) => {
      console.error(
        `editAccountAPI failed with http status = ${response.status}`
      );
      return Promise.reject(response.json());
    });
};

export default editAccountService;
