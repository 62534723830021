import { acceptBookingRequest as endpoint } from "../apiEndPoints";
import { AcceptBookingRequestProps } from "../getBookingRequestTypes";

const acceptBookingRequestAPI = async ({
  header,
  bookingRequest,
  bookingId,
}: AcceptBookingRequestProps) => {
  return fetch(endpoint(bookingId), {
    method: "PUT",
    headers: header,
    body: JSON.stringify({ ...bookingRequest }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export default acceptBookingRequestAPI;
